import {DeleteIcon} from '@chakra-ui/icons';
import {Button, Flex, ModalFooter} from '@chakra-ui/react';
import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

import {Props} from './ShiftTemplateFooter.types';

const ShiftTemplateFooter = ({
  shiftTemplate,
  isEditable,
  setShowConfirmationModal,
  showConfirmationModal,
  handleDeleteShiftTemplate,
  handleClose,
  handleEditClick,
  formIsDirty,
  isSubmitting,
  openingButNoDay,
  totalShiftTime,
}: Props) => (
  <ModalFooter position="sticky" bottom={0} bg="white">
    <Flex w="100%" justifyContent="flex-end" gap="2rem" bg="white">
      {shiftTemplate && !isEditable ? (
        <>
          <Button
            leftIcon={<DeleteIcon display={['none', 'inherit']} />}
            h="3rem"
            w={['100%', '12rem']}
            variant="outline"
            color="#313871"
            border="2px solid #313871"
            onClick={() => {
              setShowConfirmationModal(true);
            }}
          >
            Delete template
          </Button>
          <Button
            type="button"
            h="3rem "
            w={['100%', '6rem']}
            variant="primary"
            onClick={e => {
              handleEditClick(e);
            }}
          >
            Edit
          </Button>
          {showConfirmationModal && (
            <ConfirmationModal
              isOpen={showConfirmationModal}
              handleClick={() => {
                handleDeleteShiftTemplate();
                setShowConfirmationModal(false);
              }}
              onClose={() => setShowConfirmationModal(false)}
            />
          )}
        </>
      ) : (
        <>
          <Button h="3rem" w={['60%', '6rem']} variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            type="submit"
            h="3rem"
            w={['60%', '6rem']}
            isDisabled={!formIsDirty || isSubmitting || openingButNoDay || totalShiftTime.asHours() > 16}
          >
            Save
          </Button>
        </>
      )}
    </Flex>
  </ModalFooter>
);

export default ShiftTemplateFooter;
