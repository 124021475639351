import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {BsSend} from 'react-icons/bs';
import {FaHeart, FaStar} from 'react-icons/fa6';
import uuid from 'react-uuid';
import {FiInfo} from 'react-icons/fi';
import moment from 'moment';
import {useLegacyFacilityId, useTimeZone} from '../CreateShift/appSelectors';
import ApplicantProfileModal from '../CalendarPage/Overlay/ApplicantProfileModal';
import {useInviteNdProToShift} from '../../../shared/gql/shifts/mutations';
import useAppSelector from '../../../shared/hooks/useAppSelector';
import {ShiftDetails} from '../Shift.types';
import {getInviteExpirationDate} from '../CalendarPage/helpers/helpers';

type Props = {
  data: any;
  isLoading: boolean;
  refetchShiftOpenings: () => void;
  setActionDone: (value: boolean) => void;
  inviteShiftId: string;
  shiftDetails: ShiftDetails;
};

const tableCellStyles = {
  borderBottom: '1px solid #EBEFF3',
};

const commonButtonProps = {
  size: 'sm',
  variant: 'outline',
};

const reliabilityColorMapping = {
  New: '#BEDFFF',
  Stellar: '#C6F6D5',
  Exceptional: '#FBD38D',
};

const NdProWorkForceTable = ({
  data,
  isLoading,
  refetchShiftOpenings,
  setActionDone,
  inviteShiftId,
  shiftDetails,
}: Props) => {
  const legacyFacilityId = useLegacyFacilityId();
  const sessionUser = useAppSelector((state: any) => state?.usersData?.sessionUser);
  const toast = useToast();
  const [confirmationModal, setConfirmationModal] = useState<{
    isOpen?: boolean;
    ndProId?: string;
    inviteMessage?: string;
  }>({
    isOpen: false,
    ndProId: '',
    inviteMessage: '',
  });

  const inviteRef = React.useRef(null);

  const [bypassAutoSelect, setByPassAutoSelect] = useState(true);

  const [inviteNdProToShift, {loading}] = useInviteNdProToShift();

  const getReliabilityText = (score: number, shifts: number) => {
    const numericReliabilityRating = Number(score);
    // Check if the applicant is new (no shifts completed)
    if (shifts === 0) {
      return 'New';
    }
    // Check the reliability rating score and return the corresponding text
    if (numericReliabilityRating >= 89 && numericReliabilityRating <= 100) {
      return 'Stellar';
    }
    if (numericReliabilityRating >= 64 && numericReliabilityRating < 89) {
      return 'Exceptional';
    }
    // Return blank if none of the above conditions are met
    return '';
  };

  const [applicantModalData, setApplicantModalData] = useState<{isOpen: boolean; ndProData: any}>({
    isOpen: false,
    ndProData: null,
  });

  const onInviteClick = async () => {
    if (inviteShiftId)
      try {
        const response = await inviteNdProToShift({
          variables: {
            inviteShiftId: Number(inviteShiftId),
            ndProId: Number(confirmationModal?.ndProId),
            bypassAutoSelect: sessionUser?.facilitySelectionType === 'facility' ? false : bypassAutoSelect,
            inviteMessage: confirmationModal?.inviteMessage || '',
          },
        });
        if (response) {
          setConfirmationModal({isOpen: false, ndProId: ''});
          refetchShiftOpenings();
          toast({
            title: 'Success',
            description: 'NdPro Invited Successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Error',
            description: 'Failed to invite ndpro.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log('Error', error);
      }
  };
  const timeZone = useTimeZone();

  const shiftDate = moment
    .utc(shiftDetails.startTime ?? '')
    .tz(timeZone ?? '')
    .format('DD/MM/YY');
  const currentDate = moment().format('DD/MM/YY');

  const expirationDate = getInviteExpirationDate(shiftDetails?.startTime || '', timeZone || 'UTC')?.format('ddd MM/D');

  return (
    <>
      <TableContainer
        className="app-table-container"
        marginTop="15px"
        overflowY="auto"
        style={{maxHeight: '37rem', overflowY: 'auto'}}
      >
        <Box className="table-box" overflowY="auto" maxHeight="100%">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead bg="#F9FAFB">
              <Tr>
                <Th sx={tableCellStyles} fontSize="12px" fontWeight="500" textTransform="capitalize">
                  NDPro Name
                </Th>
                <Th textAlign="center" sx={tableCellStyles} fontSize="12px" fontWeight="500" textTransform="capitalize">
                  Shifts Worked
                </Th>
                <Th textAlign="center" sx={tableCellStyles} fontSize="12px" fontWeight="500" textTransform="capitalize">
                  Reliability
                </Th>
                <Th textAlign="center" sx={tableCellStyles} fontSize="12px" fontWeight="500" textTransform="capitalize">
                  Rating
                </Th>
                <Th textAlign="center" sx={tableCellStyles} fontSize="12px" fontWeight="500" textTransform="capitalize">
                  {' '}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={6} textAlign="center">
                    <Spinner mt="25px" thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                  </Td>
                </Tr>
              ) : (
                data
                  // ?.sort((a: {rating: number}, b: {rating: number}) => Number(b?.rating) - Number(a?.rating))
                  ?.map((ndpro: any) => {
                    const {
                      id: ndProId,
                      name,
                      image,
                      totalShiftsWorked: totalShifts,
                      totalFacilityShiftsWorked: totalFacilityShifts,
                      reliabilityScore,
                      rating,
                      relationToFacility,
                      inviteShiftValidations,
                    } = ndpro;

                    const relation = relationToFacility?.find(
                      (facility: any) =>
                        Number(facility?.facilityId) === Number(legacyFacilityId) &&
                        facility?.relation === 'facility-favorite'
                    )?.relation;

                    const reliabilityText = getReliabilityText(reliabilityScore, totalShifts);
                    const badgeColor =
                      reliabilityColorMapping[reliabilityText as keyof typeof reliabilityColorMapping] || '#C6F6D5';

                    return (
                      <Tr key={uuid()} fontSize="1rem">
                        <Td width="12%" textAlign="center" sx={tableCellStyles} fontSize="14px">
                          <Flex
                            position="relative"
                            onClick={() =>
                              setApplicantModalData({
                                isOpen: true,
                                ndProData: {...ndpro, relation, totalShifts, totalFacilityShifts, nurseId: ndProId},
                              })
                            }
                            style={{cursor: 'pointer'}}
                          >
                            <Flex alignItems="center" justifyContent="flex-start" position="relative">
                              <Avatar size="sm" name={name} src={image?.src} bg="blue.500" color="white" />
                              {relation === 'facility-favorite' && (
                                <Box position="absolute" right="-5px" bottom="0" color="red.500" top="19px">
                                  <Icon as={FaHeart} boxSize="14px" color="#FC8181" />
                                </Box>
                              )}
                            </Flex>
                            <Flex
                              className="ndproname-container"
                              flexDir="column"
                              alignItems="flex-start"
                              justifyContent="center"
                              ml="12px"
                              _hover={{fontWeight: '600', textDecoration: 'underline'}}
                            >
                              <Text>{name}</Text>
                            </Flex>
                          </Flex>
                        </Td>
                        <Td textAlign="center" sx={tableCellStyles} fontSize="14px" fontWeight="500">
                          <Text fontWeight="600">For You: {totalFacilityShifts}</Text>
                          <Text fontSize="12px" color="grey">
                            Total: {totalShifts}
                          </Text>
                        </Td>
                        <Td textAlign="center" sx={tableCellStyles} fontSize="14px" fontWeight="500">
                          {reliabilityText ? (
                            <Badge
                              bg={badgeColor}
                              fontSize="0.7rem"
                              borderRadius="0.3125rem"
                              fontWeight="semibold"
                              padding="0.2rem 0.4rem"
                              color="#22543D"
                              textTransform="capitalize"
                            >
                              {reliabilityText}
                            </Badge>
                          ) : (
                            '-'
                          )}
                        </Td>
                        <Td textAlign="center" sx={tableCellStyles} fontSize="14px" fontWeight="500">
                          <Flex flexDir="row" justifyContent="center" alignItems="center">
                            {rating ? (
                              <>
                                <Text color="#404B89" fontWeight="600">
                                  {rating}
                                </Text>
                                <Flex mt="-1px" ml="2px">
                                  <FaStar color="#404B89" />
                                </Flex>
                              </>
                            ) : (
                              <Text fontWeight="600">N/A</Text>
                            )}
                          </Flex>
                        </Td>
                        <Td sx={tableCellStyles}>
                          <Flex flexDirection="column" alignItems="center">
                            <Button
                              bg="#303f9f"
                              size="sm"
                              width="100%"
                              onClick={() => {
                                setConfirmationModal({...confirmationModal, isOpen: true, ndProId});
                              }}
                              // isDisabled={isPastShift || sessionUser?.role === 'Viewer'}
                            >
                              Invite
                            </Button>
                            {inviteShiftValidations?.isOvertime && (
                              <Tooltip
                                label="Selecting this NDPro will result in overtime rates on the Facility's invoice unless the contract explicitly indicates otherwise."
                                placement="bottom"
                                hasArrow
                              >
                                <Text color="#FC8181" fontSize="10px" fontWeight="500">
                                  Overtime shift
                                </Text>
                              </Tooltip>
                            )}
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })
              )}
            </Tbody>
          </Table>
        </Box>
      </TableContainer>{' '}
      {confirmationModal?.isOpen && (
        <AlertDialog
          isOpen={confirmationModal?.isOpen}
          leastDestructiveRef={inviteRef}
          onClose={() => {
            setConfirmationModal({isOpen: false, ndProId: ''});
            setByPassAutoSelect(true);
          }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent display="flex" margin="auto">
              <AlertDialogHeader fontSize="1.3rem" boxShadow="0px 1px 2px 0px #0000000D">
                <Flex alignItems="center">
                  <IconButton
                    sx={{commonButtonProps}}
                    aria-label="button"
                    icon={<BsSend />}
                    variant="simple"
                    pr={2}
                    fontSize="1.25rem"
                  />
                  <Text fontWeight={700}>Invite NDPro</Text>
                </Flex>
              </AlertDialogHeader>

              <AlertDialogBody fontSize="1rem" pt="1rem">
                <Flex flexDirection="column" pb={2} gap="0.8rem">
                  <Flex flexDirection="column" gap="0.2rem">
                    <Text fontSize="medium">Invite Message</Text>
                    <Textarea
                      onChange={e => setConfirmationModal({...confirmationModal, inviteMessage: e?.target?.value})}
                      value={confirmationModal?.inviteMessage}
                    />
                  </Flex>
                  {sessionUser?.facilitySelectionType === 'nursedash' && (
                    <Flex alignItems="center" gap="0.3rem">
                      <Switch
                        id="toggle"
                        onChange={e => {
                          setByPassAutoSelect(e?.target?.checked);
                        }}
                        isChecked={bypassAutoSelect}
                      />
                      <Text>NurseDash Select</Text>
                      <Tooltip
                        style={{position: 'relative'}}
                        label={
                          shiftDate === currentDate
                            ? 'When NurseDash Select is enabled, selection on new applications will be evaluated immediately.'
                            : `When NurseDash Select is enabled, we will select the best applicant for you if the invitee does not respond by end of day ${expirationDate}.`
                        }
                        placement="bottom"
                      >
                        <span>
                          <FiInfo color="black" />
                        </span>
                      </Tooltip>
                    </Flex>
                  )}
                </Flex>
              </AlertDialogBody>

              <AlertDialogFooter
                justifyContent="space-between"
                boxShadow="2px 2px 8px rgba(0, 0, 0, 0.2)"
                mt="1rem"
                gap="1rem"
              >
                <Button
                  variant="secondary"
                  width="50%"
                  onClick={() => {
                    setConfirmationModal({isOpen: false, ndProId: ''});
                  }}
                >
                  Cancel
                </Button>
                <Button width="50%" onClick={onInviteClick} isLoading={loading}>
                  Invite NDPro
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {applicantModalData?.isOpen && (
        <ApplicantProfileModal
          handleIsModalOpen={setApplicantModalData}
          refetchShiftOpenings={refetchShiftOpenings}
          setActionDone={setActionDone}
          ndProData={applicantModalData?.ndProData}
        />
      )}
    </>
  );
};

export default NdProWorkForceTable;
