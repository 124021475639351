import {Box, Flex} from '@chakra-ui/react';
import React from 'react';
import './PreviewCard.scss'; 

type Props = {
  shiftRole: string;
  lastMessage: string;
  createdAt: string;
  nurseName: string;
  nameOfSender: string;
  shiftEndTime: string;
  shiftStartTime: string;
  isNurse: boolean;
  hasUnread: boolean;
  onClick: () => void;
};
const PreviewCards = ({
  shiftRole,
  lastMessage,
  createdAt,
  nurseName,
  nameOfSender,
  isNurse,
  shiftEndTime,
  shiftStartTime,
  hasUnread,
  onClick,
}: Props) => {
  const getInitials = React.useCallback((senderName: string) => {
    const words = senderName.split(' ');
    let initials = '';

    if (words.length <= 3) {
      // Get the first letter of each word
      words.forEach((word: string) => {
        initials += word[0];
      });
    } else {
      // Get the first letter of the first and last word
      initials = words[0][0] + words[words.length - 1][0];
    }

    return initials.toUpperCase();
  }, []);

  const getAvatarBgColor = React.useCallback((senderIsNurse: boolean) => {
    if (senderIsNurse) {
      return 'rgba(197, 201, 228, 1)';
    }
    return 'rgba(49, 57, 113, 1)';
  }, []);

  return (
    <Flex
      flexDirection="column"
      bg="rgba(248, 248, 248, 1)"
      borderRadius="0.5rem"
      sx={{
        boxShadow: '0rem 0.0625rem 0.125rem 0rem rgba(0, 0, 0, 0.06), 0rem 0.0625rem 0.1875rem 0rem rgba(0, 0, 0, 0.1)',
      }}
      border="0.0625rem solid rgba(241, 241, 241, 1)"
      padding={["0.5rem 0.2rem" , "0.5rem 0.875rem"]}
      gap=".5rem"
      w={["50vh","30%"]}
      onClick={() => onClick()}
      className={`message-card ${hasUnread ? 'has-unread-indicator' : ''}`}
    >
      <Flex justifyContent="space-between">
        <Box color="rgba(37, 40, 90, 1)" fontWeight="600" fontSize="0.875rem">
          {nurseName}
        </Box>
        <Box fontWeight="400" fontSize="0.75rem" color="#718096">
          {createdAt}
        </Box>
      </Flex>

      <Flex alignItems="flex-start" wrap="wrap">
        <Flex gap=".5rem" alignItems="center">
          <Flex
            border="0.0625rem rgba(226, 232, 240, 1)"
            boxShadow="0rem 0.0625rem 0.125rem 0rem rgba(0, 0, 0, 0.06), 0rem 0.0625rem 0.1875rem 0rem rgba(0, 0, 0, 0.1);"
            borderRadius="50%"
            color="rgba(255, 255, 255, 1)"
            bg={getAvatarBgColor(isNurse)}
            w="2rem"
            h="2rem"
            justifyContent="center"
            alignItems="center"
          >
            {getInitials(nameOfSender)}
          </Flex>
          <Flex>
            <Box fontWeight="400" fontSize="0.875rem" flex="1">
              {lastMessage}
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex>
        <Box fontWeight="600" fontSize="0.75rem" color="rgba(113, 128, 150, 1)">
          {`${shiftRole} |`}
        </Box>
        <Box fontWeight="600" fontSize="0.75rem" color="rgba(113, 128, 150, 1)">
          <Box marginLeft=".2rem" fontWeight="600" fontSize="0.75rem" color="rgba(113, 128, 150, 1)">
            {shiftStartTime} - {shiftEndTime}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PreviewCards;
