import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';

export type User = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  facilityId: string;
  facilityName: string;
  role: string;
  legacyFacilityId: number;
  deletedAt?: string;
  lastLoginWeb?: string;
  lastLoginMobile?: string;
  lastActiveWeb?: string;
  lastActiveMobile?: string;
};

export type LoggedInUser = {
  role: string;
  facilityName: string;
  facilityId: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  legacyFacilityId: number;
  facilitySegment: string;
  facilityHomeMarket: string;
  facilitySelectionType: string;
  facilityTimeZone: string;
  facilityLoggedInAs: string;
}[];

export type SessionUser = {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  legacyFacilityId: number;
  nextGenFacilityId: string;
  role: string;
  facilityName: string;
  segmentName: string;
  facilityHomeMarket: string;
  facilitySelectionType: string;
  timeZone: string;
  ndAdmin: boolean;
  access?: {
    facilityName: string;
    facilityId: string;
    legacyFacilityId: string;
    isDefault: boolean;
    role: string;
  }[];
};

export type UsersState = {
  users: User[];
  loading: boolean;
  error: string | null;
  user: User | null;
  loggedInUser: LoggedInUser | null;
  ndAdmin: string;
  legacyFacilityLoggedInAs: string;
  sessionUser: SessionUser | null;
  ldContext: boolean;
};

const initialState: UsersState = {
  users: [],
  loading: false,
  error: null,
  user: null,
  loggedInUser: null,
  ndAdmin: '',
  legacyFacilityLoggedInAs: '',
  sessionUser: null,
  ldContext: false,
};

export const usersSlice = createSlice({
  name: 'usersData',
  initialState,
  reducers: {
    getUsers: state => {
      state.loading = true;
      state.error = null;
    },
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
      state.loading = false;
      state.error = null;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setLoggedInUser: (state, action: PayloadAction<any>) => {
      state.loggedInUser = action.payload;
    },
    setSessionUser: (state, action: PayloadAction<any>) => {
      state.sessionUser = action.payload;
    },
    setLdContext: (state, action: PayloadAction<any>) => {
      state.ldContext = action.payload;
    },
    setNdAdmin: (state, action: PayloadAction<any>) => {
      state.ndAdmin = action.payload;
    },
    setLegacyFacilityLoggedInAs: (state, action: PayloadAction<any>) => {
      state.legacyFacilityLoggedInAs = action.payload;
    },
    getNdAdmin: state => {
      state.loading = true;
      state.error = null;
    },
    createUser: (state: UsersState) => {
      state.loading = true;
      state.error = null;
    },
    deleteUser: (state: UsersState) => {
      state.loading = true;
      state.error = null;
    },
    updateUser: (state: UsersState) => {
      state.loading = true;
      state.error = null;
    },
  },
});

export const {
  getUsers,
  setUsers,
  createUser,
  deleteUser,
  setUser,
  updateUser,
  setLoggedInUser,
  getNdAdmin,
  setNdAdmin,
  setLegacyFacilityLoggedInAs,
  setSessionUser,
  setLdContext,
} = usersSlice.actions;

export const selectUsers = (state: RootState) => state.usersData.users;

export default usersSlice.reducer;
