import React, {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {
  FlexProps,
  useColorModeValue,
  Flex,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  VStack,
  Text,
  MenuList,
  MenuItem,
  Avatar,
} from '@chakra-ui/react';

import {FiMenu} from 'react-icons/fi';
import {useAuth0} from '@auth0/auth0-react';
import FacilitySelector from '../FacilitySelector/FacilitySelector';
import {useGetLoggedInUserDetails} from '../../../gql/Users/queries';
import {useLoggedInFacilityDetails} from '../../../gql/Facility/queries';

import {setNdAdmin, setLegacyFacilityLoggedInAs, setSessionUser} from '../../../slices/usersSlice';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import ndLogo from '../../../../assets/logo/nd-logo-with-name.svg';
import {trackUserLogoutEvent} from '../../../mixpanel/events/login';

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({onOpen, ...rest}: MobileProps) => {
  const {user} = useAuth0();
  const token = useAppSelector((state: {auth: {token: string}}) => state.auth.token);
  const idFromStorage = localStorage.getItem('legacyFidParam');
  const facilityIdFromAuth0 = user?.nextGenFacilityId;
  const legacyFacilityIdFromAuth0 = user?.legacyFacilityId;
  const reduxAdmin = useAppSelector((state: any) => state?.usersData?.ndAdmin);
  const roleFromDecodedToken = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.nextGenRole
  );
  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.canAssumeIdentity
  );
  const allFacilitiesAccess = isInternalAdmin && idFromStorage;
  const facilityIdToUse = idFromStorage || legacyFacilityIdFromAuth0 || facilityIdFromAuth0;

  const {data: loggedInUserDetails} = useGetLoggedInUserDetails(user?.iuaID, {
    skip: !user?.iuaID,
    fetchPolicy: 'cache-first',
  });
  const {data: loggedInFacilityDetails,} = useLoggedInFacilityDetails(facilityIdToUse, {
    skip: !facilityIdToUse,
    fetchPolicy: 'cache-first',
  });
  const facilityIdFromLegacyId = loggedInFacilityDetails?.getFacility.id;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const pageView = searchParams.get('view');

  const getHeader = () => {
    if (location.pathname.toLowerCase() === '/weekly' || pageView === 'weekly') return 'Weekly';
    if (pageView === 'monthly-overview') return 'Monthly Overview';
    if (pageView === 'monthly-confirmed') return 'Monthly Confirmed';
    return '';
  };
  useEffect(() => {
    if (facilityIdFromLegacyId) {
      const legacyFacilityIdConverted = facilityIdFromLegacyId;
      dispatch(setNdAdmin(legacyFacilityIdConverted));
      dispatch(setLegacyFacilityLoggedInAs(idFromStorage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityIdFromLegacyId, token, allFacilitiesAccess]);

  useEffect(() => {
    if (!loggedInFacilityDetails || !loggedInUserDetails) return;
    const userDetails = loggedInUserDetails?.getUser;
    const facilityDetails = loggedInFacilityDetails?.getFacility;
    const {firstName, lastName, email, id, access} = userDetails;
    const {
      id: nextGenFacilityId,
      legacyFacilityId: legacyFacilityIdData,
      name,
      segmentName,
      homeMarketName,
      selection,
      timeZone,
    } = facilityDetails;
    const sessionUserDetails = {
      firstName,
      lastName,
      email,
      userId: id,
      legacyFacilityId: legacyFacilityIdData,
      nextGenFacilityId,
      role: roleFromDecodedToken,
      facilityName: name,
      segmentName,
      facilityHomeMarket: homeMarketName,
      facilitySelectionType: selection,
      timeZone,
      ndAdmin: allFacilitiesAccess,
      access,
    };
    dispatch(setSessionUser(sessionUserDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loggedInUserDetails,
    facilityIdFromAuth0,
    dispatch,
    legacyFacilityIdFromAuth0,
    loggedInFacilityDetails,
    facilityIdToUse,
    token,
    reduxAdmin,
    facilityIdFromLegacyId,
  ]);

  return (
    <Flex
      className="mobile-nav"
      ml={roleFromDecodedToken === 'Viewer' ? '' : {base: 0, md: 60}}
      px={{base: 4, md: 4}}
      height="3.5rem"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth={['0px', '1px']}
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="space-between"
      {...rest}
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
    >
      <IconButton
        display={{base: 'flex', md: 'none'}}
        onClick={onOpen}
        variant="unstyled"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize={["sm" , "3xl"]} fontWeight="700">
        {roleFromDecodedToken === 'Viewer' ? (
          <img className="nd-logo" src={ndLogo} alt="NurseDash logo" />
        ) : (
          getHeader()
        )}
      </Text>
      <Flex>
        <FacilitySelector isIcon />
        <HStack spacing={{base: '0', md: '6'}}>
          <Flex alignItems="center">
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{boxShadow: 'none'}}
                _hover={{cursor: 'default'}}
                borderLeft="1px solid #E4E4E7"
              >
                <HStack marginLeft=".7rem">
                  <VStack display={{base: 'none', md: 'flex'}} alignItems="flex-start" spacing="1px" ml="2">
                    <Text fontSize="sm" fontWeight="bold">
                      {user?.email}
                    </Text>
                  </VStack>

                  <Avatar
                    size="sm"
                    src="https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  />
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <MenuItem display={{base: 'flex', md: 'none'}}>Name</MenuItem>
                <MenuItem
                  onClick={() =>
                    // ToDo: This causes a minor glitch in the UI. Fix it
                    {
                      trackUserLogoutEvent({});
                      window.location.assign('/logout');
                    }
                  }
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default MobileNav;
