import {gql, MutationHookOptions, useMutation} from '@apollo/client';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const setDefaultFacility = () => gql`
  mutation SetDefaultFacility($userDefaultFacilityInput: SetUserDefaultFacilityInput!) {
    setDefaultFacility(userDefaultFacilityInput: $userDefaultFacilityInput) {
      firstName
      lastName
      email
      type
      access {
        facilityId
        role
        legacyFacilityId
        isDefault
      }
    }
  }
`;

const useSetDefaultFacility = (options?: MutationHookOptions<any, any>) => {
  const {client} = useApolloClients();

  return useMutation<any, any>(setDefaultFacility(), {
    ...options,
    client,
    onError: err => {
      console.log('err from useSetDefaultFacility', err);
    },
  });
};

export default useSetDefaultFacility;
