import React, {useEffect, useState} from 'react';
import {
  Flex,
  Text,
  Stack,
  Box,
  StackDivider,
  Drawer,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Badge,
  Avatar,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import {LazyQueryExecFunction, OperationVariables} from '@apollo/client';
import {
  FaRegHeart,
  FaEllipsis,
  FaRegUser,
  FaArrowRightToBracket,
  FaAnglesRight,
  FaRegComment,
  FaHeart,
} from 'react-icons/fa6';
import {RiDeleteBinLine} from 'react-icons/ri';
import {FiEdit2, FiInfo} from 'react-icons/fi';
import {TbMessageCircle} from 'react-icons/tb';
import {LuArchive, LuMinusSquare} from 'react-icons/lu';
import {MdOutlineHistory} from 'react-icons/md';

import {isNull} from 'lodash';
import uuid from 'react-uuid';
import moment from 'moment';

import {useNavigate} from 'react-router-dom';
import {useSessionUser, useTimeZone} from '../../CreateShift/appSelectors';

import {
  useArchiveApplicants,
  useCancelShiftOpening,
  useRemoveRelationToFacility,
  useSetRelationToFacility,
  useUpdateApplicantStatus,
} from '../../../../shared/gql/CalendarShifts/mutations';
import {FilteredApplicantInfo, ShiftDetails} from '../../Shift.types';
import ReasonDropdown from '../ReasonDropdown';
import RateNDProModal from '../RateNDProModal';
import ApplicantProfileModal from '../Overlay/ApplicantProfileModal';
import PreviewCardModal from '../../../Messages/PreviewCardModal';
import formatTimeWithZone from '../../../Messages/helpers/helpers';
import AddToDNRListModal from '../Overlay/AddtoDNRListModal';
import {
  useGetAllCompletedShiftsLegacyQuery,
  useGetShiftInviteWorkforceAvailabilityQuery,
} from '../../../../shared/gql/shifts/queries';
import ConfirmationModal from '../Overlay/ConfirmationModal';
import ShiftInformationCard from './ShiftInformationCard';
import {useGetAllOverviewShifts} from '../../../../shared/gql/CalendarShifts/queries';
import ApplicantListRow from './ApplicantListRow';
import {openingStatusOrder, OpeningStatusOrderType} from './constants';
import {OpeningsV3} from '../../../../shared/types/openings';
import {trackShiftView} from '../../../../shared/mixpanel/page-views';
import {
  trackArchiveApplicantEvent,
  trackCancelShiftEvent,
  trackNdproFavEvent,
  trackNdproRemoveFavEvent,
  trackUnArchiveApplicantEvent,
} from '../../../../shared/mixpanel/events';
import useAppSelector from '../../../../shared/hooks/useAppSelector';
import ActivityHistoryDrawer from '../../../../shared/components/ActivityHistory/ActivityHistoryDrawer';
import NdProWorkForceTable from '../../ShiftInvite/NdProworkForceTable';
import {getInviteExpirationDate} from '../helpers/helpers';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  finalFocusRef: React.RefObject<any>;
  shift: ShiftDetails;
  position: string;
  unitName: string;
  startDate: Date;
  endDate: Date;
  searchAllShifts: () => void;
  getShiftDetails: LazyQueryExecFunction<any, OperationVariables>;
  isLoading: boolean;
};

const commonButtonProps = {
  size: 'sm',
  variant: 'outline',
};

const tableCellStyles = {
  borderBottom: '1px solid #EBEFF3',
};

const reliabilityColorMapping = {
  New: '#BEDFFF',
  Stellar: '#C6F6D5',
  Exceptional: '#FBD38D',
  '-': '',
};

type ApplicantModalDataType = {
  isOpen: boolean;
  applicantId: number | null | undefined;
  shiftIds: number[];
};

type ConfirmationModalData = {
  isOpen: boolean;
  data: {
    id: number;
    name: string;
    shiftIds: number[];
    refetch?: () => void;
  } | null;
};

const ShiftInformationDrawer = ({
  shift,
  position,
  unitName,
  isOpen,
  onClose,
  finalFocusRef,
  startDate,
  endDate,
  searchAllShifts,
  getShiftDetails,
  isLoading,
}: Props) => {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [currentOpeningDetail, setCurrentOpeningDetail] = useState<OpeningsV3 | null>(null);
  const [applicantModalData, setApplicantModalData] = useState<ApplicantModalDataType>({
    isOpen: false,
    applicantId: null,
    shiftIds: [],
  });
  const [isModalOpen, setIsModalOpen] = useState<ConfirmationModalData>({isOpen: false, data: null});
  const [DNRlistModaldata, setDNRlistModalData] = useState<{
    isOpen: boolean;
    nurseId: number | null;
    relation?: string | null;
  }>({nurseId: null, isOpen: false});
  const [shifts, setShifts] = useState<ShiftDetails | null>(null);
  const [actionDone, setActionDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.canAssumeIdentity
  );

  const {isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose} = useDisclosure();

  const [activityHistoryData, setActivityHistoryDrawerData] = useState<{shiftId: string | null}>({shiftId: null});

  const handleInfoDrawerClose = () => {
    onClose();
    if (actionDone) searchAllShifts();
  };

  const timeZone = useTimeZone();

  const toast = useToast();
  const navigate = useNavigate();

  const formatShiftTime = (time: Date, formattingTimeZone?: string) =>
    formattingTimeZone ? moment.utc(time).tz(formattingTimeZone).format('h:mm A') : moment.utc(time).format('h:mm A');

  const convertedStartTime = shift ? formatShiftTime(new Date(shift.startTime), timeZone) : '';
  const convertedEndTime = shift ? formatShiftTime(new Date(shift.endTime), timeZone) : '';

  const {isOpen: isAlertDialogOpen1, onOpen: onAlertDialogOpen1, onClose: onAlertDialogClose1} = useDisclosure();
  const {isOpen: isAlertDialogOpen2, onOpen: onAlertDialogOpen2, onClose: onAlertDialogClose2} = useDisclosure();
  const {isOpen: isRateNDProModalOpen, onOpen: onRateNDProModalOpen, onClose: onRateNDProModalClose} = useDisclosure();

  const [cancelShiftOpening] = useCancelShiftOpening();
  const sessionUser = useSessionUser();

  const handleCancelOpening = async ({
    shiftId,
    shiftOpeningId,
    input,
  }: {
    shiftId: string;
    shiftOpeningId: string;
    input: {reason: string; isPolicyViolated: boolean};
  }) => {
    try {
      await cancelShiftOpening({
        variables: {
          shiftId,
          shiftOpeningId,
          input,
        },
      });

      setSelectedReason('');

      onAlertDialogClose1();
      onAlertDialogClose2();
      onClose();
      searchAllShifts();
    } catch (error) {
      console.error('Error canceling shift opening:', error);
    }
  };

  const cancelRef = React.useRef(null);
  const isPastShift = moment().isAfter(moment.tz(shift.endTime, timeZone));

  // Code for getCompletedShift data
  const [ids, setIds] = useState<number[]>([]);
  const [triggerQuery, setTriggerQuery] = useState(false);

  const [archivedList, setArchivedList] = useState<FilteredApplicantInfo[]>([]);

  const [uniqueApplicants, setUniqueApplicants] = useState<FilteredApplicantInfo[]>([]);

  const {data: completedShiftData, refetch} = useGetAllCompletedShiftsLegacyQuery({
    variables: {ids},
    skip: !triggerQuery,
  });

  const [archiveApplicants, {loading: archivingApplicant}] = useArchiveApplicants();

  const {
    data: allOverviewShiftsData,
    loading: getAllOverviewShiftsLoading,
    refetch: refetchApplicantList,
  } = useGetAllOverviewShifts({
    variables: {ids},
    skip: !triggerQuery,
  });

  useEffect(() => {
    if (shift && shift.openingDetails) {
      // Ensure openingDetails is defined and not null

      const refShiftIds = shift.openingDetails
        .filter(detail => !['Opened', 'OpenedWithApplicants'].includes(detail.status))
        .map(detail => Number(detail.refShiftId));
      setIds(refShiftIds);
      setTriggerQuery(true);
    } else {
      // Optionally handle the case where shift or openingDetails is not available
      setIds([]);
    }
    return () => setTriggerQuery(false);
  }, [shift]);

  useEffect(() => {
    const refShiftIds = shift?.openingDetails?.map(detail => Number(detail.refShiftId));
    setIds(refShiftIds);
    setTriggerQuery(true);
    return () => setTriggerQuery(false);
  }, [shift]);

  const findApplicantData = (dataset: any[], applicantId: number) =>
    dataset
      ?.map(shiftData => shiftData?.applicantList?.find((applicant: any) => applicant?.applicantId === applicantId))
      .find(applicant => applicant !== undefined);

  useEffect(() => {
    // Sort openingDetails in the existing shift object based on their status
    if (
      allOverviewShiftsData &&
      Array.isArray(allOverviewShiftsData.allOverviewShifts) &&
      shift &&
      shift?.openingDetails
    ) {
      const applicantsWithShiftId = allOverviewShiftsData.allOverviewShifts.flatMap((overviewShift: any) =>
        overviewShift.applicantList.map((applicant: any) => ({
          ...applicant,
          shiftId: overviewShift.id,
        }))
      );

      // Identify unique applied applicants
      const appliedApplicants = applicantsWithShiftId
        .filter((applicant: any) => ['applied', 'invited', 'declined'].includes(applicant.status))
        .filter((applicant, index, self) => self.findIndex(t => t.nurseId === applicant.nurseId) === index);

      const filteredApplicants = allOverviewShiftsData.allOverviewShifts.flatMap(
        (overviewShift: any) => overviewShift.applicantList || []
      );

      const selectedApplicants = filteredApplicants.filter(
        (applicant: {status: string}) => applicant.status === 'selected'
      );

      // Filter out applicants who are selected for any opening from being eligible for other openings
      const eligibleApplicants = appliedApplicants.filter(
        (appliedApplicant: {nurseId: number}) =>
          !selectedApplicants.some(selectedApplicant => appliedApplicant?.nurseId === selectedApplicant?.nurseId)
      );

      // sort applicant based on favourite and reliability score
      eligibleApplicants
        .sort((a, b) => {
          if (a.relation === 'facility-favorite' && b.relation !== 'facility-favorite') {
            return -1;
          }
          if (a.relation !== 'facility-favorite' && b.relation === 'facility-favorite') {
            return 1;
          }
          return b.reliabilityScore - a.reliabilityScore;
        })
        .sort((a, b) => {
          if (a.status === 'applied' && b.relation !== 'applied') {
            return -1;
          }
          return 1;
        });

      setUniqueApplicants(eligibleApplicants.filter((item: any) => isNull(item.archiveDate)));
      setArchivedList(eligibleApplicants.filter((item: any) => !isNull(item.archiveDate)));

      // Update the shift object with sorted openingDetails
      const openedOpenings = shift?.openingDetails?.filter(opening =>
        ['Opened', 'OpenedWithApplicants']?.includes(opening?.status)
      );

      const otherOpenings = shift?.openingDetails?.filter(
        opening => !['Opened', 'OpenedWithApplicants']?.includes(opening?.status)
      );

      const filteredOpenings = openedOpenings?.map((opening, index) =>
        eligibleApplicants?.length > index
          ? {...opening, status: 'OpenedWithApplicants'}
          : {...opening, status: 'Opened'}
      );

      // Change opening status based on applied candidates
      const sortedOpeningDetails = [...otherOpenings, ...filteredOpenings].sort((a, b) => {
        if (a.status === 'Selected' && b.status === 'Selected') {
          // If both are "OpenedWithApplicants", sort by reliability score and relation

          const applicantDataForA = findApplicantData(
            allOverviewShiftsData?.allOverviewShifts || [],
            Number(a?.legacyApplicationId)
          );

          const applicantDataForB = findApplicantData(
            allOverviewShiftsData?.allOverviewShifts || [],
            Number(b?.legacyApplicationId)
          );

          if (
            applicantDataForA.relation === 'facility-favorite' &&
            applicantDataForB.relation !== 'facility-favorite'
          ) {
            return -1;
          }
          if (
            applicantDataForA.relation !== 'facility-favorite' &&
            applicantDataForB.relation === 'facility-favorite'
          ) {
            return 1;
          }
          return applicantDataForB.reliabilityScore - applicantDataForA.reliabilityScore;
        }
        // Otherwise, sort by status order
        return (
          openingStatusOrder[a.status as keyof OpeningStatusOrderType] -
          openingStatusOrder[b.status as keyof OpeningStatusOrderType]
        );
      });

      setShifts({
        ...shift,
        openingDetails: sortedOpeningDetails,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift, allOverviewShiftsData, ids]);

  const openedWithApplicantsCount =
    shifts?.openingDetails.filter(detail => detail.status === 'OpenedWithApplicants').length || 0;

  const shiftIds = shift?.openingDetails ? shift.openingDetails.map(detail => Number(detail.refShiftId)) : [];

  const handleArchiveApplicants = async (applicantId: number, isUnarchive?: boolean, applicantData?: any) => {
    const data = await archiveApplicants({
      variables: {
        id: applicantId,
        archiveDate: !isUnarchive ? moment(new Date()).toISOString() : null,
        shiftIds,
      },
    });

    if (data?.data) {
      if (!isUnarchive) {
        trackArchiveApplicantEvent(applicantData, applicantId);
      } else {
        trackUnArchiveApplicantEvent(applicantData, applicantId);
      }
      refetch();
      refetchApplicantList();
    }
  };

  const handleDeleteIconClick = (openingDetail: OpeningsV3) => {
    setCurrentOpeningDetail(openingDetail);

    const currentTime = moment();
    const shiftStartTime = moment(shift.startTime);
    const hoursUntilShiftStarts = shiftStartTime.diff(currentTime, 'hours');

    if (openingDetail.status === 'Confirmed' && hoursUntilShiftStarts <= openingDetail.cancellationPolicy?.policy) {
      onAlertDialogOpen2();
    } else if (
      ['Opened', 'OpenedWithApplicants', 'PendingReview', 'Selected', 'Confirmed'].includes(openingDetail.status)
    ) {
      onAlertDialogOpen1();
    }
  };

  // shift invites
  const openedOpeningList = shift?.openingDetails?.filter(opening =>
    ['Opened', 'OpenedWithApplicants']?.includes(opening?.status)
  );

  const inviteShiftId = openedOpeningList?.[0]?.refShiftId;

  const {
    data: shiftInviteWorkforceAvailabilityList,
    loading: ndproWorkForceListLoading,
    refetch: getShiftInviteWorkForceRefetch,
  } = useGetShiftInviteWorkforceAvailabilityQuery({
    variables: {inviteShiftId},
    skip: openedOpeningList?.length === 0 || !inviteShiftId,
  });

  const [setRelationToFacility] = useSetRelationToFacility();
  const [removeRelationToFacility] = useRemoveRelationToFacility();

  const refetchShiftOpenings = () => {
    setTimeout(() => {
      getShiftDetails({
        variables: {
          shiftId: shift?.shiftId,
        },
      });
    }, 3000);

    refetchApplicantList();
    refetch();
    getShiftInviteWorkForceRefetch();
  };
  const displayToast = (description: string, toastStatus: 'loading' | 'error' | 'info' | 'warning' | 'success') => {
    toast({
      title: description,
      status: toastStatus,
      duration: 3000,
      isClosable: true,
    });
  };
  const handleLegacyFavorite = async (nurseId: number, relation: string, name: string, data?: any) => {
    if (relation === 'facility-favorite') {
      try {
        const setRelationResponse = await removeRelationToFacility({
          variables: {
            nurseId,
            reason: '',
            relation: 'facility-favorite',
          },
        });
        if (setRelationResponse.data) {
          displayToast(`${name} has been removed from your Favorites List`, 'error');
          trackNdproRemoveFavEvent(data, nurseId);
          refetch();
          refetchShiftOpenings();
        } else if (!setRelationResponse.data) {
          displayToast('Remove applicant from favourite failed!', 'error');
        }
      } catch (error) {
        console.log('error:', error);
      }
    } else {
      try {
        const setRelationResponse = await setRelationToFacility({
          variables: {
            nurseId,
            reason: '',
            relation: 'facility-favorite',
          },
        });
        if (setRelationResponse.data) {
          displayToast(`${name} has been added to your Favorites List`, 'success');
          trackNdproFavEvent(data, nurseId);
          refetch();
          refetchShiftOpenings();
        } else if (!setRelationResponse.data) {
          displayToast('Add applicant to favourite failed!', 'error');
        }
      } catch (error) {
        console.log('error:', error);
      }
    }
  };

  const [updateApplicantStatus, {loading: updateApplicantStatusLoading}] = useUpdateApplicantStatus();

  const handleUpdateApplicantStatus = async (
    id: number,
    applicantName: string,
    select: boolean,
    shiftIdList: number[],
    refetchFunction?: any
  ) => {
    setActionDone(true);
    setLoading(true);
    await updateApplicantStatus({
      variables: {
        id,
        select,
        shiftIds: shiftIdList,
      },
    })
      .then(() => {
        displayToast(
          select ? `${applicantName} is Selected for your shift!` : `${applicantName} was Unselected for your shift!`,
          'success'
        );
        setIsModalOpen({isOpen: false, data: null});
        setTimeout(() => {
          getShiftDetails({
            variables: {
              shiftId: shift?.shiftId,
            },
          });
          refetchApplicantList();
          refetch();
          if (refetchFunction) {
            refetchFunction();
          }
          setLoading(false);
        }, 3000);
      })
      .catch(() => {
        displayToast(select ? 'Applicant selection failed!' : 'Remove Applicantion failed!', 'error');
      });
  };

  const newDate = moment
    .utc(shift.startTime ?? '')
    .tz(timeZone ?? '')
    .format('dddd MMMM Do');
  const currentDate = moment();
  const openingDate = moment(shift.startTime);
  const isBeforeCurrentDate = openingDate.isBefore(currentDate);

  // Rate NDPro
  const [rateNdProModalDetails, setRateNdProModalDetails] = useState<any>(null);

  const handleRateNDProClick = (id: number, isCompleted?: boolean) => {
    if (completedShiftData?.allCompletedShifts) {
      const applicantData = completedShiftData?.allCompletedShifts?.find(shiftData =>
        shiftData?.applicantList?.find(applicant => applicant?.applicantId === id) ? shiftData : null
      );

      const modalDetails = {
        unitName,
        position,
        date: newDate,
        startTime: convertedStartTime,
        endTime: convertedEndTime,
        shiftBreak: shift.break,
        timeZone,
        applicantData,
        isCompleted,
        id,
      };
      setRateNdProModalDetails(modalDetails);
      onRateNDProModalOpen();
    }
  };

  const getStatusText = (
    status: string,
    actionAt: string,
    actionByName: string,
    legacyApplicationId: number,
    shiftEndTime: string,
    shiftStartTime: string,
    actionBy: string
  ) => {
    const endTime = moment.tz(shiftEndTime, timeZone || 'UTC');

    const startTime = moment.tz(shiftStartTime, timeZone || 'UTC');

    const isShiftInProgress = moment().isBetween(
      moment.tz(startTime, timeZone || 'UTC').add(5, 'minute'),
      moment.tz(endTime, timeZone || 'UTC').add(5, 'minute')
    );

    switch (status) {
      case 'Completed':
        return (
          <Text
            color="#3cb371"
            fontWeight="600"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => handleRateNDProClick(legacyApplicationId, true)}
          >
            Completed
          </Text>
        );
      case 'Confirmed':
        if (isShiftInProgress) {
          return (
            <Text color="#3cb371" fontWeight="600">
              In Progress
            </Text>
          );
        }
        return (
          <Text color="#3cb371" fontWeight="600">
            Confirmed
          </Text>
        );

      case 'Cancelled': {
        const formatCancelledAtTime = (cancelledAt: string, formattedTimeZone?: string) => {
          if (!cancelledAt) return '';
          return formattedTimeZone
            ? moment.utc(cancelledAt).tz(formattedTimeZone).format('MMM Do h:mm A')
            : moment.utc(cancelledAt).format('MMM Do h:mm A');
        };
        const cancelledAtTime = formatCancelledAtTime(actionAt, timeZone);
        const actionByNameDisplay = actionByName || '';
        const fullMessage =
          actionBy === '22d8cbb0-9a48-4596-b4c1-92c699ac8374'
            ? `Cancelled on ${cancelledAtTime}`
            : `Cancelled on ${cancelledAtTime} by ${actionByNameDisplay}`;
        const displayMessage = fullMessage.length > 30 ? `${fullMessage.slice(0, 27)}...` : fullMessage;

        return (
          <Tooltip label={fullMessage} placement="top" hasArrow>
            <Text>{displayMessage}</Text>
          </Tooltip>
        );
      }
      case 'Opened':
        return isBeforeCurrentDate ? 'Opened' : 'Searching...';
      case 'OpenedWithApplicants':
        return <Text color="#405FF2">Select Applicant</Text>;
      case 'Selected':
        return (
          <Text color="#718096" fontWeight="500">
            Pending Confirmation
          </Text>
        );
      case 'PendingCheckIn':
        return (
          <Text color="#718096" fontWeight="500">
            Pending Clock-in
          </Text>
        );
      case 'PendingClockIn':
        return (
          <Text color="#718096" fontWeight="500">
            Pending Clock-out
          </Text>
        );
      case 'PendingReview':
        return (
          <Text
            cursor="pointer"
            textDecoration="underline"
            color="#405FF2"
            onClick={sessionUser?.role === 'Viewer' ? () => {} : () => handleRateNDProClick(legacyApplicationId)}
          >
            Rate NDPro
          </Text>
        );
      case 'NoCallNoShow':
        return 'No Check-In';
      default:
        return status;
    }
  };

  // Messaging
  const {isOpen: isMessagesModalOpen, onOpen, onClose: onMessageModalClose} = useDisclosure();
  const [cardClickedDetails, setCardClickedDetails] = useState<any>(null);

  const handleMessageClick = (data: {
    channelId?: number;
    legacyApplicationId?: number;
    nurseName: string;
    shiftRole?: string;
  }) => {
    const startTime = formatTimeWithZone(shift?.startTime, 'M/D/YYYY h:mma', timeZone);
    const endTime = formatTimeWithZone(shift?.endTime, 'h:mma', timeZone);

    // Try to find applicant data from allOverviewShiftsData or completedShiftData
    const applicantData =
      findApplicantData(allOverviewShiftsData?.allOverviewShifts || [], Number(data?.legacyApplicationId)) ||
      findApplicantData(completedShiftData?.allCompletedShifts || [], Number(data?.legacyApplicationId));

    const channelIdFromShift = applicantData?.channelId;

    if (data?.channelId || channelIdFromShift) {
      setCardClickedDetails({
        id: data?.channelId ?? channelIdFromShift,
        nurseName: data?.nurseName,
        shiftStartTime: startTime,
        shiftEndTime: endTime,
        shiftRole: `${unitName} / ${position}`,
      });
      onOpen();
    }
  };

  const handleSuccessResponse = (response: string) => {
    toast({
      title: response === 'success' ? 'Message sent' : 'Failed to send message',
      status: response === 'success' ? 'success' : 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDNRActionClick = (id: number) => {
    const applicantData = completedShiftData?.allCompletedShifts?.find(shiftData =>
      shiftData?.applicantList?.some(applicant => applicant?.applicantId === id)
    );

    if (applicantData) {
      const applicant = applicantData.applicantList.find((app: any) => app.applicantId === id);
      const nurseId = applicant?.nurseId;
      const relation = applicant?.relation || '';

      setDNRlistModalData({
        isOpen: true,
        nurseId: Number(nurseId),
        relation,
      });
    }
  };

  const getShiftStatus = () => {
    const currentTime = moment();
    const startTime = moment(shift.startTime);
    const endTime = moment(shift.endTime);

    if (currentTime.isBefore(startTime)) {
      return 'Upcoming'.charAt(0) + 'Upcoming'.slice(1).toLowerCase();
    }
    if (currentTime.isSameOrAfter(startTime) && currentTime.isBefore(endTime)) {
      return 'In Progress'.charAt(0) + 'In Progress'.slice(1).toLowerCase();
    }
    if (currentTime.isSameOrAfter(endTime)) {
      return 'Past'.charAt(0) + 'Past'.slice(1).toLowerCase();
    }
    return 'Completed'.charAt(0) + 'Completed'.slice(1).toLowerCase();
  };

  const getBadgeStyles = () => {
    const status = getShiftStatus();
    switch (status) {
      case 'Upcoming':
        return {colorScheme: 'orange', borderColor: '#ED8936'};
      case 'In Progress':
        return {colorScheme: '#F0FFF4', borderColor: '#48BB78'};
      case 'Past':
        return {colorScheme: 'blue', borderColor: '#4DA7FF'};
      default:
        return {colorScheme: 'gray', borderColor: 'gray.300'};
    }
  };

  const filledStatuses = ['Confirmed', 'Completed', 'PendingReview', 'PendingClockIn', 'PendingCheckIn'];

  const filledCount = shifts?.openingDetails.reduce((acc, detail) => {
    if (filledStatuses.includes(detail.status)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const totalCount = shifts?.openingDetails.reduce((acc, detail) => {
    if (!['Cancelled'].includes(detail.status)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const getReliabilityText = (totalShifts: number, reliabilityScore: number | null): string => {
    if (totalShifts === 0) {
      return 'New';
    }
    if (reliabilityScore !== null) {
      if (reliabilityScore >= 89) {
        return 'Stellar';
      }
      if (reliabilityScore >= 64) {
        return 'Exceptional';
      }
    }
    return '-';
  };

  const getImageForShiftId = (refShiftId: number) => {
    const nurseImage = allOverviewShiftsData?.allOverviewShifts.find(app => app.id === refShiftId);
    const image = nurseImage?.applicantList[0]?.image;
    return image;
  };

  const getPositionType = (refShiftId: number): string[] => {
    const positionTypesArray = allOverviewShiftsData?.allOverviewShifts.find(app => app.id === refShiftId);
    const ndProPosition = positionTypesArray?.applicantList[0]?.positionType;

    return ndProPosition || [];
  };

  useEffect(() => {
    trackShiftView(shift, shift?.shiftId);
  }, [shift]);

  // bypass autoSelect
  const isBypassAutoSelect = allOverviewShiftsData?.allOverviewShifts?.every(
    overviewShift => overviewShift?.bypassAutoSelect === true
  );

  const shiftDate = moment
    .utc(shift.startTime ?? '')
    .tz(timeZone ?? '')
    .format('DD/MM/YY');

  const formattedCurrentDate = moment().format('DD/MM/YY');

  const expirationDate = getInviteExpirationDate(shift?.startTime || '', timeZone || 'UTC')?.format('dddd (M/D)');

  const getByPassAutoSelectText = () => {
    if (sessionUser?.facilitySelectionType === 'nursedash') {
      if (isBypassAutoSelect) {
        if (shiftDate === formattedCurrentDate) {
          return (
            <Text mt="15px" mr="15px" fontSize="14px" color="black" as="sup">
              All eligible applications are evaluated for selection at time of receipt.{' '}
            </Text>
          );
        }
        return (
          <Flex gap="0.2rem" mr="15px">
            <Text mt="15px" fontSize="14px" color="black" as="sup">
              NurseDash Select paused until end of day {expirationDate}.
            </Text>
            <Tooltip
              style={{position: 'relative'}}
              label="All eligible applications will be evaluated for selection when NurseDash Select resumes."
              placement="bottom"
            >
              <span>
                <FiInfo color="black" />
              </span>
            </Tooltip>
          </Flex>
        );
      }
      return (
        // NurseDash select is disabled pending invite (i) Manual selection can be made at anytime.
        <Flex gap="0.2rem" mr="15px">
          <Text mt="15px" fontSize="14px" color="black" as="sup">
            NurseDash Select is disabled pending shift application responses.
          </Text>
          <Tooltip style={{position: 'relative'}} label="Manual selection can be made at anytime." placement="bottom">
            <span>
              <FiInfo color="black" />
            </span>
          </Tooltip>
        </Flex>
      );
    }

    return null;
  };

  return (
    <Flex className="shift-information-drawer" overflow="auto">
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleInfoDrawerClose}
        finalFocusRef={finalFocusRef}
        size={{base: 'full', md: 'xl'}}
      >
        <DrawerOverlay style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}} />
        <DrawerContent overflow="auto">
          <DrawerHeader background="#F9FAFB">
            <Flex flexDir="row" alignItems="center" className="header-container" justifyContent="space-between">
              <Flex flexDir="row" alignItems="center">
                <Box ml="-10px" cursor="pointer" onClick={handleInfoDrawerClose}>
                  <FaAnglesRight size="14px" />
                </Box>
                <Text fontSize="1rem" fontWeight="600" pl="10px">
                  Shift Details
                </Text>
              </Flex>

              <Flex ml="-15px" pr="30px">
                <Badge
                  colorScheme={getBadgeStyles().colorScheme}
                  fontSize="12px"
                  px="2"
                  py="1"
                  borderRadius="full"
                  border="1px solid"
                  borderColor={getBadgeStyles().borderColor}
                >
                  {getShiftStatus()}
                </Badge>
              </Flex>
            </Flex>
          </DrawerHeader>
          <DrawerCloseButton />

          {shifts && (
            <ShiftInformationCard
              shift={shift}
              unitName={unitName}
              position={position}
              convertedEndTime={convertedEndTime}
              convertedStartTime={convertedStartTime}
              newDate={newDate}
              isExpanded={
                Number(shifts?.openingDetails?.length) +
                  Number(uniqueApplicants?.length) +
                  Number(archivedList?.length) <
                4
              }
            />
          )}

          {isLoading || !shifts ? (
            <Box height="80%">
              <Flex margin="0" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <Spinner thickness="0.25rem" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
              </Flex>
            </Box>
          ) : (
            <Flex className="table-container" direction="column" flex="1">
              <Flex flexDir="row" alignItems="baseline">
                <Text ml="0.9375rem" fontSize="1rem" fontWeight="600" color="black">
                  Shift Openings
                </Text>
                <Text ml="5px" fontSize="12px" fontWeight="600">
                  ({filledCount}/{totalCount} Filled)
                </Text>
              </Flex>

              <TableContainer className="shift-table" marginTop="0.3125rem">
                <Box className="table-box" overflowY="auto" maxHeight="100%">
                  <Stack divider={<StackDivider />} spacing="5" align="stretch">
                    <Table className="shift-table" variant="simple" colorScheme="whiteAlpha">
                      <Thead bg="#F9FAFB">
                        <Tr>
                          <Th
                            textAlign="start"
                            sx={tableCellStyles}
                            fontSize="0.75rem"
                            fontWeight="500"
                            textTransform="capitalize"
                          >
                            Assigned Nurse
                          </Th>
                          <Th
                            textAlign="center"
                            sx={tableCellStyles}
                            fontSize="0.75rem"
                            fontWeight="500"
                            textTransform="capitalize"
                          >
                            <Box>
                              Status
                              {completedShiftData?.allCompletedShifts?.some(
                                completedShift => completedShift?.checkInTime !== null
                              ) && (
                                <Text fontSize="0.625rem" fontWeight="400">
                                  ClockIn - ClockOut
                                </Text>
                              )}
                            </Box>
                          </Th>
                          <Th
                            textAlign="center"
                            sx={tableCellStyles}
                            fontSize="0.75rem"
                            fontWeight="500"
                            textTransform="capitalize"
                          >
                            <Box>
                              Shifts Worked
                              <Text fontSize="0.625rem" fontWeight="400">
                                For you / Total
                              </Text>
                            </Box>
                          </Th>
                          <Th
                            textAlign="center"
                            sx={tableCellStyles}
                            fontSize="0.75rem"
                            fontWeight="500"
                            textTransform="capitalize"
                          >
                            Reliability
                          </Th>
                          {sessionUser?.role !== 'Viewer' && (
                            <>
                              {' '}
                              <Th
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.75rem"
                                fontWeight="500"
                                textTransform="capitalize"
                              >
                                Chat
                              </Th>
                              <Th
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.75rem"
                                fontWeight="500"
                                textTransform="capitalize"
                              >
                                Actions
                              </Th>
                            </>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {shifts?.openingDetails.map(detail => {
                          const isAfterStartTime = moment().isAfter(moment(shift.startTime));

                          const applicantData =
                            findApplicantData(
                              allOverviewShiftsData?.allOverviewShifts || [],
                              Number(detail?.legacyApplicationId)
                            ) ||
                            findApplicantData(
                              completedShiftData?.allCompletedShifts || [],
                              Number(detail?.legacyApplicationId)
                            );

                          const completedShift = completedShiftData?.allCompletedShifts?.find(
                            data => data?.id?.toString() === detail?.refShiftId?.toString()
                          );

                          const reliabilityText = getReliabilityText(
                            applicantData?.total_shifts,
                            applicantData?.reliabilityScore
                          );
                          const badgeColor =
                            reliabilityColorMapping[reliabilityText as keyof typeof reliabilityColorMapping];

                          const imageUrl = getImageForShiftId(Number(detail.refShiftId));

                          const positionTypes = getPositionType(Number(detail.refShiftId)) || '';

                          const matchingPosition = positionTypes?.find(positionItem => positionItem === position);

                          return (
                            <Tr key={uuid()} fontSize="1rem">
                              <Td
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.875rem"
                                fontWeight="600"
                                style={{opacity: detail.status === 'Cancelled' ? 0.5 : 1}}
                              >
                                <Flex alignItems="center" justifyContent="flex-start" key={uuid()}>
                                  {[
                                    'Confirmed',
                                    'Completed',
                                    'Selected',
                                    'PendingReview',
                                    'PendingClockIn',
                                    'PendingCheckIn',
                                  ].includes(detail.status) ? (
                                    <Flex
                                      alignItems="center"
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        setApplicantModalData({
                                          isOpen: true,
                                          applicantId: detail?.legacyApplicationId,
                                          shiftIds: [Number(detail?.refShiftId)],
                                        });
                                      }}
                                    >
                                      <Flex pr="5px" position="relative">
                                        <Avatar
                                          name={detail.ndProName}
                                          src={imageUrl}
                                          size="sm"
                                          bg="blue.500"
                                          color="white"
                                        />
                                        {applicantData?.relation === 'facility-favorite' && (
                                          <Box position="absolute" right="0" bottom="0" color="red.500" top="18px">
                                            <Icon as={FaHeart} boxSize="14px" color="#FC8181" />
                                          </Box>
                                        )}
                                      </Flex>
                                      <Flex
                                        ml="5px"
                                        className="ndproname-container"
                                        flexDir="column"
                                        alignItems="flex-start"
                                      >
                                        <Flex alignItems="baseline" gap="2px">
                                          {' '}
                                          <Text
                                            color={
                                              ['Selected', 'PendingCheckIn', 'PendingClockIn']?.includes(detail?.status)
                                                ? 'black'
                                                : '#3cb371'
                                            }
                                            _hover={{textDecor: 'underline'}}
                                            whiteSpace="nowrap"
                                            textOverflow="ellipsis"
                                            overflow="hidden"
                                            maxWidth="12rem"
                                          >
                                            {detail?.ndProName}{' '}
                                          </Text>{' '}
                                        </Flex>
                                        <Flex>
                                          <Text fontSize="12px" fontWeight="500" color="grey">
                                            {matchingPosition || ''}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  ) : (
                                    <Text>Unassigned</Text>
                                  )}
                                </Flex>
                              </Td>
                              <Td
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.875rem"
                                fontWeight="600"
                                style={{opacity: detail.status === 'Cancelled' ? 0.5 : 1}}
                              >
                                <Flex flexDirection="column">
                                  {getStatusText(
                                    detail.status,
                                    detail.actionAt,
                                    detail.actionByName,
                                    Number(detail?.legacyApplicationId),
                                    shift.endTime,
                                    shift.startTime,
                                    detail.actionBy
                                  )}
                                  {completedShift?.checkInTime && completedShift?.checkOutTime && (
                                    <Tooltip
                                      label={
                                        isInternalAdmin
                                          ? `ClockIn : ${completedShift?.checkInSupervisorName} | ${completedShift?.checkInSupervisorEmail} | ${completedShift?.checkInSupervisorPhone} 
                                              ClockOut: ${completedShift?.checkOutSupervisorName} | ${completedShift?.checkOutSupervisorEmail} | ${completedShift?.checkOutSupervisorPhone}`
                                          : ''
                                      }
                                      placement="bottom"
                                    >
                                      <Text fontSize="10px" fontWeight="500" color="grey" cursor="pointer">
                                        {formatTimeWithZone(completedShift?.checkInTime, 'h:mma', timeZone)} -{' '}
                                        {formatTimeWithZone(completedShift?.checkOutTime, 'h:mma', timeZone)}
                                      </Text>
                                    </Tooltip>
                                  )}
                                  {applicantData?.isOvertime && (
                                    <Tooltip
                                      label="The Facility will be charged overtime rates for this shift, as this NDPro is scheduled to work more than 40 hours within this calendar week."
                                      placement="bottom"
                                      hasArrow
                                    >
                                      <Text color="#FC8181" fontSize="10px" fontWeight="500">
                                        Overtime shift
                                      </Text>
                                    </Tooltip>
                                  )}
                                </Flex>
                              </Td>

                              <Td
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.875rem"
                                fontWeight="600"
                                style={{opacity: detail.status === 'Cancelled' ? 0.5 : 1}}
                              >
                                {[
                                  'PendingReview',
                                  'PendingClockIn',
                                  'PendingCheckIn',
                                  'Completed',
                                  'Confirmed',
                                  'Selected',
                                ].includes(detail.status) &&
                                applicantData &&
                                applicantData?.total_facility_shifts !== null &&
                                applicantData?.total_shifts !== null ? (
                                  <Text fontWeight="600" fontSize="12px">
                                    {applicantData?.total_facility_shifts} / {applicantData?.total_shifts}
                                  </Text>
                                ) : (
                                  <Text fontWeight="600" fontSize="12px">
                                    -
                                  </Text>
                                )}
                              </Td>
                              <Td
                                textAlign="center"
                                sx={tableCellStyles}
                                fontSize="0.875rem"
                                fontWeight="600"
                                style={{opacity: detail.status === 'Cancelled' ? 0.5 : 1}}
                              >
                                {[
                                  'PendingReview',
                                  'PendingClockIn',
                                  'PendingCheckIn',
                                  'Completed',
                                  'Confirmed',
                                  'Selected',
                                ].includes(detail.status) ? (
                                  <Badge
                                    bg={badgeColor}
                                    fontSize="0.7rem"
                                    borderRadius="0.3125rem"
                                    fontWeight="semibold"
                                    padding="0.2rem 0.4rem"
                                    textTransform="capitalize"
                                  >
                                    {reliabilityText}
                                  </Badge>
                                ) : (
                                  <Text fontWeight="600" fontSize="12px">
                                    -
                                  </Text>
                                )}
                              </Td>
                              {sessionUser?.role !== 'Viewer' && (
                                <>
                                  <Td textAlign="center" sx={tableCellStyles} fontSize="0.875rem" fontWeight="600">
                                    <IconButton
                                      sx={commonButtonProps}
                                      aria-label="Send message"
                                      icon={<FaRegComment />}
                                      variant="ghost"
                                      onClick={() =>
                                        handleMessageClick({
                                          legacyApplicationId: Number(detail?.legacyApplicationId),
                                          nurseName: detail.ndProName,
                                        })
                                      }
                                      isDisabled={
                                        ![
                                          'Selected',
                                          'Confirmed',
                                          'PendingReview',
                                          'Completed',
                                          'PendingCheckIn',
                                          'PendingClockIn',
                                        ].includes(detail.status)
                                      }
                                    />
                                  </Td>
                                  <Td textAlign="center" sx={tableCellStyles}>
                                    <Box
                                      className="icon-box"
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="center"
                                    >
                                      <Menu>
                                        <MenuButton
                                          as={IconButton}
                                          icon={<FaEllipsis />}
                                          variant="ghost"
                                          _hover={{bg: 'gray.400'}}
                                          isDisabled={
                                            (isPastShift &&
                                              ['Opened', 'OpenedWithApplicants', 'Selected']?.includes(
                                                detail?.status
                                              )) ||
                                            (detail?.status === 'Cancelled' && !isInternalAdmin)
                                          }
                                        />
                                        <MenuList>
                                          {[
                                            'Selected',
                                            'Confirmed',
                                            'PendingReview',
                                            'Completed',
                                            'PendingCheckIn',
                                            'PendingClockIn',
                                          ].includes(detail.status) && (
                                            <MenuItem
                                              onClick={() => {
                                                setActionDone(true);
                                                setApplicantModalData({
                                                  isOpen: true,
                                                  applicantId: detail?.legacyApplicationId,
                                                  shiftIds: [Number(detail?.refShiftId)],
                                                });
                                              }}
                                            >
                                              <Box>
                                                <IconButton
                                                  sx={commonButtonProps}
                                                  aria-label="button"
                                                  colorScheme="black"
                                                  icon={<FaRegUser />}
                                                  variant="simple"
                                                />
                                                View Profile
                                              </Box>
                                            </MenuItem>
                                          )}

                                          {[
                                            'Selected',
                                            'Confirmed',
                                            'PendingReview',
                                            'Completed',
                                            'PendingCheckIn',
                                            'PendingClockIn',
                                          ].includes(detail.status) && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMessageClick({
                                                  legacyApplicationId: Number(detail?.legacyApplicationId),
                                                  nurseName: detail.ndProName,
                                                });
                                              }}
                                            >
                                              <Box className="button-modal-box" paddingRight="0.625rem">
                                                <IconButton
                                                  sx={commonButtonProps}
                                                  aria-label="button"
                                                  colorScheme="red"
                                                  icon={<TbMessageCircle />}
                                                  variant="simple"
                                                />
                                                Send Message
                                              </Box>
                                            </MenuItem>
                                          )}

                                          {!['PendingClockIn', 'PendingReview', 'Completed', 'Cancelled'].includes(
                                            detail?.status
                                          ) &&
                                            !isPastShift && (
                                              <MenuItem
                                                onClick={() => {
                                                  localStorage.setItem('unitName', JSON.stringify(unitName));
                                                  localStorage.setItem('startDate', JSON.stringify(startDate));
                                                  localStorage.setItem('endDate', JSON.stringify(endDate));
                                                  localStorage.setItem('fromPage', window.location.pathname);
                                                  navigate(`/edit-shift/${shift.shiftId}/${detail?.refShiftId}`);
                                                }}
                                                isDisabled={
                                                  moment().isAfter(moment(shift.endTime)) ||
                                                  (isAfterStartTime &&
                                                    ![
                                                      'Confirmed',
                                                      'Selected',
                                                      'Opened',
                                                      'OpenedWithApplicants',
                                                    ].includes(detail.status))
                                                }
                                              >
                                                <Box>
                                                  <IconButton
                                                    sx={commonButtonProps}
                                                    aria-label="button"
                                                    colorScheme="black"
                                                    icon={<FiEdit2 />}
                                                    variant="simple"
                                                  />
                                                  Edit Opening
                                                </Box>
                                              </MenuItem>
                                            )}

                                          {![
                                            'PendingReview',
                                            'Completed',
                                            'PendingCheckIn',
                                            'PendingClockIn',
                                            'Cancelled',
                                          ].includes(detail?.status) &&
                                            !isPastShift && (
                                              <MenuItem
                                                onClick={() => {
                                                  setActionDone(true);
                                                  handleDeleteIconClick(detail);
                                                }}
                                              >
                                                <Flex alignItems="center">
                                                  <IconButton
                                                    sx={commonButtonProps}
                                                    aria-label="button"
                                                    icon={<RiDeleteBinLine />}
                                                    variant="simple"
                                                  />
                                                  <Text>Cancel Opening</Text>
                                                </Flex>
                                              </MenuItem>
                                            )}

                                          {['Selected'].includes(detail.status) && (
                                            <MenuItem
                                              color="red"
                                              onClick={() => {
                                                setActionDone(true);
                                                setIsModalOpen({
                                                  isOpen: true,
                                                  data: {
                                                    id: detail?.legacyApplicationId,
                                                    name: detail?.ndProName,
                                                    shiftIds: [Number(detail?.refShiftId)],
                                                  },
                                                });
                                              }}
                                            >
                                              <Box className="button-modal-box" paddingRight="0.625rem">
                                                <IconButton
                                                  sx={commonButtonProps}
                                                  aria-label="button"
                                                  colorScheme="red"
                                                  icon={<LuMinusSquare color="red" />}
                                                  variant="simple"
                                                />
                                                Unselect
                                              </Box>
                                            </MenuItem>
                                          )}

                                          {['PendingReview', 'Completed'].includes(detail?.status) && (
                                            <MenuItem
                                              onClick={() => {
                                                setActionDone(true);
                                                handleLegacyFavorite(
                                                  Number(applicantData?.nurseId),
                                                  applicantData?.relation || '',
                                                  detail?.ndProName,
                                                  applicantData
                                                );
                                              }}
                                            >
                                              <Box>
                                                <IconButton
                                                  sx={commonButtonProps}
                                                  aria-label="button"
                                                  icon={<FaRegHeart />}
                                                  variant="simple"
                                                />
                                                {applicantData?.relation === 'facility-favorite'
                                                  ? 'Remove from favorites list'
                                                  : 'Add to favorites list'}
                                              </Box>
                                            </MenuItem>
                                          )}

                                          {['PendingReview', 'Completed'].includes(detail?.status) && (
                                            <MenuItem
                                              onClick={() => {
                                                handleDNRActionClick(detail?.legacyApplicationId);
                                              }}
                                              color="red"
                                            >
                                              <Box>
                                                <IconButton
                                                  sx={commonButtonProps}
                                                  aria-label="button"
                                                  icon={<FaArrowRightToBracket color="red" />}
                                                  variant="simple"
                                                />
                                                {applicantData?.relation === 'do-not-return'
                                                  ? 'Remove from "Do not return" list'
                                                  : 'Add to "Do not return" list'}
                                              </Box>
                                            </MenuItem>
                                          )}

                                          {(isInternalAdmin ||
                                            (isInternalAdmin && ['Cancelled'].includes(detail?.status))) && (
                                            <MenuItem
                                              onClick={() => {
                                                setActivityHistoryDrawerData({
                                                  shiftId: detail?.refShiftId?.toString(),
                                                });
                                                onHistoryDrawerOpen();
                                              }}
                                            >
                                              <Box color="black">
                                                <IconButton
                                                  sx={commonButtonProps}
                                                  aria-label="button"
                                                  icon={<MdOutlineHistory />}
                                                  variant="simple"
                                                />
                                                View History
                                              </Box>
                                            </MenuItem>
                                          )}
                                        </MenuList>
                                      </Menu>
                                    </Box>
                                  </Td>
                                </>
                              )}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Stack>
                </Box>

                <Box mt="2.1875rem">
                  <Flex flexDir="column" overflowY="auto">
                    {uniqueApplicants?.length > 0 && (
                      <>
                        <Flex alignItems="center" justifyContent="space-between">
                          <Text mt="15px" ml="15px" fontSize="16px" as="sup" fontWeight="600">
                            Applicants {openedWithApplicantsCount > 0 ? `(Pick ${openedWithApplicantsCount})` : ''}
                          </Text>
                          {getByPassAutoSelectText()}
                        </Flex>

                        <TableContainer
                          className="app-table-container"
                          marginTop="15px"
                          overflowY="auto"
                          style={{maxHeight: '37rem', overflowY: 'auto'}}
                        >
                          <Box className="table-box" overflowY="auto" maxHeight="100%">
                            <Table variant="simple" colorScheme="whiteAlpha">
                              <Thead bg="#F9FAFB">
                                <Tr>
                                  <Th sx={tableCellStyles} fontSize="12px" fontWeight="500" textTransform="capitalize">
                                    NDPro Name
                                  </Th>
                                  <Th
                                    textAlign="center"
                                    sx={tableCellStyles}
                                    fontSize="12px"
                                    fontWeight="500"
                                    textTransform="capitalize"
                                  >
                                    Shifts Worked
                                  </Th>
                                  <Th
                                    textAlign="center"
                                    sx={tableCellStyles}
                                    fontSize="12px"
                                    fontWeight="500"
                                    textTransform="capitalize"
                                  >
                                    Reliability
                                  </Th>
                                  <Th
                                    textAlign="center"
                                    sx={tableCellStyles}
                                    fontSize="12px"
                                    fontWeight="500"
                                    textTransform="capitalize"
                                  >
                                    Rating
                                  </Th>
                                  <Th
                                    textAlign="center"
                                    sx={tableCellStyles}
                                    fontSize="12px"
                                    fontWeight="500"
                                    textTransform="capitalize"
                                  >
                                    Status
                                  </Th>
                                  <Th
                                    textAlign="center"
                                    sx={tableCellStyles}
                                    fontSize="12px"
                                    fontWeight="500"
                                    textTransform="capitalize"
                                  >
                                    {' '}
                                  </Th>
                                  {sessionUser?.role !== 'Viewer' && (
                                    <Th
                                      textAlign="center"
                                      sx={tableCellStyles}
                                      fontSize="12px"
                                      fontWeight="500"
                                      textTransform="capitalize"
                                    >
                                      {' '}
                                    </Th>
                                  )}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {archivingApplicant || getAllOverviewShiftsLoading || isLoading ? (
                                  <Tr>
                                    <Td colSpan={6} textAlign="center">
                                      <Spinner
                                        mt="25px"
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                      />
                                    </Td>
                                  </Tr>
                                ) : (
                                  uniqueApplicants?.map(applicant => (
                                    // Returning the render of applicants
                                    <ApplicantListRow
                                      key={applicant?.applicantId}
                                      applicant={applicant}
                                      updating={updateApplicantStatusLoading || loading}
                                      handleSelectApplicant={handleUpdateApplicantStatus}
                                      setApplicantModalData={setApplicantModalData}
                                      handleArchiveApplicants={handleArchiveApplicants}
                                      shiftIds={shiftIds}
                                      handleOpenMessageModal={handleMessageClick}
                                      isPastShift={isPastShift}
                                    />
                                  ))
                                )}
                              </Tbody>
                            </Table>
                          </Box>
                        </TableContainer>
                      </>
                    )}

                    {archivedList?.length > 0 && (
                      <Box border="1px solid #EAECF0" borderRadius="0.3rem" margin=".9375rem">
                        <Accordion allowMultiple>
                          <AccordionItem border="none">
                            <Flex alignItems="center" gap="0.3125rem" padding="0.3rem 0.5rem">
                              <LuArchive color="#48BB78" />
                              <Text color="#48BB78">Archived ({archivedList?.length})</Text>
                              <AccordionButton
                                background="transparent"
                                _hover={{
                                  background: 'transparent',
                                }}
                              >
                                <AccordionIcon boxSize="4" color="#48BB78" />
                              </AccordionButton>
                            </Flex>
                            <AccordionPanel>
                              <Table variant="simple" colorScheme="whiteAlpha">
                                <Tbody>
                                  {!isPastShift &&
                                    archivedList?.map(applicant => (
                                      <ApplicantListRow
                                        key={applicant?.applicantId}
                                        applicant={applicant}
                                        updating={updateApplicantStatusLoading}
                                        handleSelectApplicant={handleUpdateApplicantStatus}
                                        setApplicantModalData={setApplicantModalData}
                                        handleArchiveApplicants={handleArchiveApplicants}
                                        handleOpenMessageModal={handleMessageClick}
                                        shiftIds={shiftIds}
                                        isPastShift={isPastShift}
                                      />
                                    ))}
                                </Tbody>
                              </Table>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                    )}

                    {openedOpeningList?.length > 0 &&
                      shiftInviteWorkforceAvailabilityList?.shiftInviteWorkforceAvailability &&
                      shiftInviteWorkforceAvailabilityList?.shiftInviteWorkforceAvailability?.length > 0 && (
                        <Box mt="2.1875rem">
                          <Flex flexDir="column" overflowY="auto">
                            <Text mt="15px" ml="15px" fontSize="16px" as="sup" fontWeight="600">
                              Invite an available favorited NDPro
                            </Text>
                            <NdProWorkForceTable
                              data={shiftInviteWorkforceAvailabilityList?.shiftInviteWorkforceAvailability}
                              isLoading={ndproWorkForceListLoading}
                              setActionDone={setActionDone}
                              refetchShiftOpenings={() => {
                                refetchApplicantList();
                                refetch();
                                getShiftInviteWorkForceRefetch();
                              }}
                              inviteShiftId={inviteShiftId}
                              shiftDetails={shift}
                            />
                          </Flex>
                        </Box>
                      )}
                  </Flex>
                  {/* )} */}
                </Box>
              </TableContainer>
            </Flex>
          )}

          <AlertDialog
            isOpen={isAlertDialogOpen1}
            leastDestructiveRef={cancelRef}
            onClose={() => {
              onAlertDialogClose1();
              setSelectedReason('');
            }}
          >
            <AlertDialogOverlay>
              <AlertDialogContent display="flex" margin="auto">
                <AlertDialogHeader fontSize="1.3rem" boxShadow="0px 1px 2px 0px #0000000D">
                  <Flex alignItems="center">
                    <IconButton
                      sx={{commonButtonProps, color: '#E53E3E'}}
                      aria-label="button"
                      icon={<RiDeleteBinLine />}
                      variant="simple"
                      pr={2}
                      fontSize="1.25rem"
                    />
                    <Text fontWeight={700}>Cancel Opening?</Text>
                  </Flex>
                </AlertDialogHeader>

                <AlertDialogBody textColor="grey" fontSize="1rem" pt="1rem">
                  Are you sure you want to cancel this opening? <br />
                  This cannot be undone.
                </AlertDialogBody>
                <AlertDialogHeader fontSize="medium">Reason for cancelling</AlertDialogHeader>
                <Flex className="drop-down-container" flexDirection="column" pl={5} pr={5} pb={2}>
                  <ReasonDropdown selectedReason={selectedReason} setSelectedReason={setSelectedReason} />
                </Flex>

                <AlertDialogFooter
                  justifyContent="space-between"
                  boxShadow="2px 2px 8px rgba(0, 0, 0, 0.2)"
                  mt="1rem"
                  gap="1rem"
                >
                  <Button
                    variant="secondary"
                    width="50%"
                    ref={cancelRef}
                    onClick={() => {
                      onAlertDialogClose1();
                      setSelectedReason('');
                    }}
                  >
                    Keep Opening
                  </Button>
                  <Button
                    colorScheme="red"
                    width="50%"
                    onClick={() => {
                      if (currentOpeningDetail?.id) {
                        const variables = {
                          shiftId: shift.shiftId,
                          shiftOpeningId: currentOpeningDetail?.id,
                          input: {
                            reason: selectedReason,
                            isPolicyViolated: !!shift.openingDetails.find(
                              detail => detail.status === 'Confirmed' && detail.cancellationPolicy?.policy
                            ),
                          },
                        };

                        handleCancelOpening(variables);
                        onAlertDialogClose2();
                        setSelectedReason('');
                      }
                    }}
                    isDisabled={!selectedReason || isPastShift}
                  >
                    Cancel Opening
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <AlertDialog
            isOpen={isAlertDialogOpen2}
            leastDestructiveRef={cancelRef}
            onClose={() => {
              onAlertDialogClose2();
              setSelectedReason('');
            }}
          >
            <AlertDialogOverlay>
              <AlertDialogContent display="flex" margin="auto">
                <AlertDialogHeader fontSize="1.3rem" boxShadow="0px 1px 2px 0px #0000000D">
                  <Flex alignItems="center">
                    <IconButton
                      sx={{commonButtonProps, color: '#E53E3E'}}
                      aria-label="button"
                      icon={<RiDeleteBinLine />}
                      variant="simple"
                      pr={2}
                      fontSize="1.25rem"
                    />
                    <Text fontWeight={700}>Cancel Opening?</Text>
                  </Flex>
                </AlertDialogHeader>

                <AlertDialogBody>
                  {(() => {
                    if (!shift?.openingDetails) return null; // Ensure openingDetails is defined

                    const openingDetail = shift.openingDetails.find(detail => {
                      const hasPolicy = detail.cancellationPolicy?.policy;
                      return detail.status === 'Confirmed' && hasPolicy;
                    });

                    if (openingDetail) {
                      return `You will be charged ${openingDetail.cancellationPolicy.hoursToPay} hours of shift pay for cancelling within ${openingDetail.cancellationPolicy.policy} hours on a confirmed applicant. Are you sure you want to cancel this opening?`;
                    }

                    return (
                      <Text>
                        Are you sure you want to cancel this opening? <br />
                        This cannot be undone.
                      </Text>
                    );
                  })()}
                </AlertDialogBody>

                <Flex className="drop-down-container" flexDirection="column" pl={5} pr={5} pb={2}>
                  <ReasonDropdown selectedReason={selectedReason} setSelectedReason={setSelectedReason} />
                </Flex>

                <AlertDialogFooter
                  justifyContent="space-between"
                  boxShadow="2px 2px 8px rgba(0, 0, 0, 0.2)"
                  mt="1rem"
                  gap="1rem"
                >
                  <Button
                    variant="secondary"
                    width="50%"
                    ref={cancelRef}
                    onClick={() => {
                      onAlertDialogClose2();
                      setSelectedReason('');
                    }}
                  >
                    Keep Opening
                  </Button>
                  <Button
                    colorScheme="red"
                    width="50%"
                    onClick={() => {
                      if (currentOpeningDetail?.id) {
                        const variables = {
                          shiftId: shift.shiftId,
                          shiftOpeningId: currentOpeningDetail?.id,
                          input: {
                            reason: selectedReason,
                            isPolicyViolated: !!shift.openingDetails.find(
                              detail => detail.status === 'Confirmed' && detail.cancellationPolicy?.policy
                            ),
                          },
                        };

                        trackCancelShiftEvent(variables, shift.shiftId);

                        handleCancelOpening(variables);
                        onAlertDialogClose2();
                        setSelectedReason('');
                      }
                    }}
                    ml={3}
                    isDisabled={!selectedReason || isPastShift}
                  >
                    Cancel Opening
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {applicantModalData?.isOpen && (
            <ApplicantProfileModal
              shiftIds={applicantModalData?.shiftIds}
              handleIsModalOpen={setApplicantModalData}
              applicantId={applicantModalData.applicantId}
              handleMessageClick={handleMessageClick}
              handleUpdateApplicantStatus={handleUpdateApplicantStatus}
              updateApplicantStatusLoading={updateApplicantStatusLoading}
              shiftData={shift}
              refetchShiftOpenings={refetchShiftOpenings}
              setConfirmationModalOpen={setIsModalOpen}
              setActionDone={setActionDone}
            />
          )}

          {rateNdProModalDetails && (
            <RateNDProModal
              isOpen={isRateNDProModalOpen}
              onClose={onRateNDProModalClose}
              modalData={rateNdProModalDetails}
              refetchShiftOpenings={refetchShiftOpenings}
              handleLegacyFavorite={handleLegacyFavorite}
              completedShiftData={completedShiftData}
              setActionDone={setActionDone}
            />
          )}

          {cardClickedDetails && (
            <PreviewCardModal
              isOpen={isMessagesModalOpen}
              onOpen={onOpen}
              onClose={onMessageModalClose}
              cardClickedDetails={cardClickedDetails}
              handleSuccessResponse={handleSuccessResponse}
              setActionDone={setActionDone}
            />
          )}

          {DNRlistModaldata?.isOpen && (
            <AddToDNRListModal
              handleIsModalOpen={setDNRlistModalData}
              nurseId={DNRlistModaldata?.nurseId}
              relation={DNRlistModaldata?.relation}
              refetchShiftOpenings={refetchShiftOpenings}
              setActionDone={setActionDone}
            />
          )}

          {isModalOpen?.data && (
            <ConfirmationModal
              isOpen={isModalOpen?.isOpen}
              onClose={() => setIsModalOpen({isOpen: false, data: null})}
              handleUpdateApplicantStatus={handleUpdateApplicantStatus}
              data={isModalOpen?.data}
            />
          )}

          <ActivityHistoryDrawer
            isOpen={isHistoryDrawerOpen}
            onClose={onHistoryDrawerClose}
            resourceId={activityHistoryData?.shiftId || ''}
            resourceType="SHIFT"
          />

          <DrawerFooter>
            <Button variant="solid" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default ShiftInformationDrawer;
