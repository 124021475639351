/* eslint-disable no-nested-ternary */
import React, {useEffect} from 'react';
import {Box, Heading, useDisclosure, useToast} from '@chakra-ui/react';
import uuid from 'react-uuid';
import {InfoIcon} from '@chakra-ui/icons';
import useGetAllLegacyChannels from '../../shared/gql/messages/queries';
import PreviewCard from './PreviewCard';
import {useLegacyFacilityId, useTimeZone} from '../Shifts/CreateShift/appSelectors';
import PreviewCardSkeleton from './PreviewCardSkeleton';
import PreviewCardModal from './PreviewCardModal';
import formatTimeWithZone from './helpers/helpers';
import useAppSelector from '../../shared/hooks/useAppSelector';
import {trackMessagePageView} from '../../shared/mixpanel/page-views/pages/othermodules';

// ToDo: Fix any type
const Messages = () => {
  const [loading, setLoading] = React.useState(true);
  const [cardClickedDetails, setCardClickedDetails] = React.useState<any>(null);
  const reduxChannels = useAppSelector(state => state.allChannels.data);
  const {data: channelsData} = useGetAllLegacyChannels();
  const legacyFacilityId = useLegacyFacilityId();
  const sessionUser = useAppSelector((state: any) => state?.usersData?.sessionUser);
  const ndAdminUserId = localStorage.getItem('legacyNDAdminUserId');
  const userId = sessionUser?.ndAdmin ? ndAdminUserId : legacyFacilityId;
  const timeZone = useTimeZone();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const handlePreviewCardClick = (
    channelId: number,
    nurseName: string,
    shiftRole: string,
    shiftStartTime: string,
    shiftEndTime: string
  ) => {
    setCardClickedDetails({id: channelId, nurseName, shiftStartTime, shiftEndTime, shiftRole});
    onOpen();
  };
  const toast = useToast();

  const handleSuccessResponse = (response: string) => {
    toast({
      title: response === 'success' ? 'Message sent' : 'Failed to send message',
      status: response === 'success' ? 'success' : 'error',
      duration: 3000,
      isClosable: true,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (channelsData) {
      setLoading(false);
    }
  }, [channelsData]);

  useEffect(() => {
    trackMessagePageView();
  }, []);

  return (
    <Box>
      {loading ? (
        Array(6)
          .fill(null)
          .map(() => <PreviewCardSkeleton key={uuid()} />)
      ) : reduxChannels && reduxChannels.length > 0 ? ( // ToDo: Fix any type
        reduxChannels.map((channel: any) => {
          const {lastMessage, applicant = {}, members} = channel;
          const nurse  = applicant?.nurse; 
          const {text: lastMessageText, createdAt, member} = lastMessage;
          const shift = applicant?.shift;
          const {name: nameOfLastMessageSender} = member.user;
          const shiftRole = shift?.role;
          const createdAtInUsersTimezone = formatTimeWithZone(createdAt, 'M/D/YYYY: h:mma', timeZone);
          const shiftStartTime = formatTimeWithZone(shift?.start_time, 'M/D/YYYY | h:mma', timeZone);
          const shiftEndTime = formatTimeWithZone(shift?.end_time, 'h:mma', timeZone);
          const nurseName = nurse?.name;
          const isNurse = nameOfLastMessageSender === nurseName;
          const currentMemember = members?.find(
            (channelMember: any) => channelMember.user.userModel === 'facility' && channelMember.user.userId === userId
          );
          const hasUnread = currentMemember && currentMemember?.unreadCount > 0;
          return (
            <Box key={channel.id} paddingBottom="1rem">
              <PreviewCard
                shiftRole={shiftRole}
                lastMessage={lastMessageText}
                createdAt={createdAtInUsersTimezone}
                nurseName={nurseName}
                nameOfSender={nameOfLastMessageSender}
                isNurse={isNurse}
                shiftStartTime={shiftStartTime}
                shiftEndTime={shiftEndTime}
                hasUnread={hasUnread}
                onClick={() =>
                  handlePreviewCardClick(channel.id, applicant?.nurse?.name, shiftRole, shiftStartTime, shiftEndTime)
                }
              />
            </Box>
          );
        })
      ) : (
        <Box textAlign="center" py={10} px={6}>
          <InfoIcon boxSize="50px" color="#38427E" />
          <Heading fontFamily="open-sans" fontWeight="700" fontSize="1.5rem" mt={6} mb={2}>
            No messages
          </Heading>
        </Box>
      )}
      {cardClickedDetails && (
        <PreviewCardModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          cardClickedDetails={cardClickedDetails}
          handleSuccessResponse={handleSuccessResponse}
        />
      )}
    </Box>
  );
};

export default Messages;
