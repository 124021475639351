import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {Box, Button, Flex, Grid, IconButton, Spinner, Text} from '@chakra-ui/react';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
// import {IoMdAddCircle} from 'react-icons/io';
import {useSearchShifts} from '../../../shared/gql/CalendarShifts/mutations';
import {MappedShiftV2, SearchShiftsPayload, Shift, UnitPosition} from '../Shift.types';
import {useFacilityId, useTimeZone} from '../CreateShift/appSelectors';
import CalenderFilters from './CalenderFilters';
import useAppSelector from '../../../shared/hooks/useAppSelector';
import {ShiftsState} from '../../../shared/slices/calShiftSlice';
import {AuthState} from '../../../shared/slices/authSlice';
import {
  checkShiftStatus,
  endOfDay,
  getFilteredSummary,
  getShiftCountsByStatus,
  processShifts,
  startOfDay,
} from './helpers/helpers';
// import {useGetSummaryOfShiftUnits} from '../../../shared/gql/CalendarShifts/queries';
import MonthlyCalendarAgendaDrawer from './ShiftInformationDrawer/MonthlyCalendarAgendaDrawer';
import {useGetFacilityUnitPositionRate} from '../../../shared/gql/shiftTemplates/queries';
import {ReduxState} from '../../../shared/types';
import {
  trackCalendarMonthlyConfirmedPageView,
  trackCalendarMonthlyOverviewPageView,
} from '../../../shared/mixpanel/page-views';
import MonthlyConfirmedShiftsListCard from './MonthlyConfirmedShiftsListCard';

const commonFlexProps = {
  border: '1px solid #F2F4F7',
  alignItems: 'center',
  justifyContent: 'center',
};

const MonthlyAgendaView = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const facilityId = useFacilityId();
  const [shiftData, setShiftData] = useState<Shift[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [sortedShiftsSummary, setSortedShiftsSummary] = useState<UnitPosition[]>([]);
  const [isReadyForSearch, setIsReadyForSearch] = useState(false);
  // const shiftsSummary = useAppSelector((state: {shifts: ShiftsState}) => state.shifts.shiftsSummary);

  const facilityUnits = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );

  const authState = useAppSelector((state: {auth: AuthState; shifts: ShiftsState}) => state.auth);
  const [selectedUnitFilter, setSelectedUnitFilter] = useState<{unitId: string; positions: string[]}[]>([]);
  const [adjustedShifts, setAdjustedShifts] = useState<Shift[]>([]);
  const timeZone = useTimeZone();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [drawerData, setDrawerData] = useState<any>({});
  const navigate = useNavigate();

  const getFirstDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);

  const getLastDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [startDate, setStartDate] = useState(getFirstDayOfMonth(new Date()));
  const [endDate, setEndDate] = useState(getLastDayOfMonth(new Date()));

  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    setCurrentDate(newDate);
    setStartDate(getFirstDayOfMonth(newDate));
    setEndDate(getLastDayOfMonth(newDate));
  };

  const goToNextMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    setCurrentDate(newDate);
    setStartDate(getFirstDayOfMonth(newDate));
    setEndDate(getLastDayOfMonth(newDate));
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const today = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const currentDay = today.getDate();
  const isCurrentMonth = today.getMonth() === month && today.getFullYear() === year;
  const isNextMonths = year > today.getFullYear() || (year === today.getFullYear() && month > today.getMonth());

  const convertedStartDate = `${moment(startOfDay(startDate)).format('YYYY-MM-DDTHH:mm:ss')}Z`;
  const convertedEndDate = `${moment(endOfDay(endDate)).format('YYYY-MM-DDTHH:mm:ss')}Z`;

  const {data: summaryOfShiftUnits} = useGetFacilityUnitPositionRate({variables: {facilityId}});

  const [searchShiftsMutation] = useSearchShifts();

  const searchShifts = async () => {
    setIsLoading(true);
    const isMounted = true;

    try {
      const searchShiftsPayload: SearchShiftsPayload = {
        facilityId,
        startDateTime: convertedStartDate,
        endDateTime: convertedEndDate,
      };

      const {data: searchData} = await searchShiftsMutation({
        variables: {
          searchShift: searchShiftsPayload,
        },
      });

      if (isMounted) {
        const fetchedShiftData = searchData?.searchShift?.map(shift => processShifts(shift)) || [];
        setShiftData(fetchedShiftData);
        setIsDataFetched(true);
      }
    } catch (error) {
      console.error('Failed to Search for Shifts', error);
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const readyForSearch = Boolean(authState.token && facilityId);
    setIsReadyForSearch(readyForSearch);
  }, [authState.token, facilityId, summaryOfShiftUnits]);

  useEffect(() => {
    if (facilityUnits) {
      const sorted = [...facilityUnits].sort((a, b) => a.name.localeCompare(b.name));
      setSortedShiftsSummary(sorted);
      if (!selectedUnitFilter?.length) {
        setSelectedUnitFilter(
          sorted?.map(unit => ({
            unitId: unit.id,
            positions: unit.positions.map(position => position.name),
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityUnits]);

  useEffect(() => {
    // Check if the component is ready for search and is still mounted
    if (isReadyForSearch) {
      searchShifts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForSearch, convertedStartDate, convertedEndDate]);

  const filters = [
    'actionRequired',
    'filledCompletely',
    'filledPartially',
    'upcomingUnfilled',
    'cancelled',
    'pastUnfilled',
  ];

  const [statusFilter, setStatusFilter] = useState(filters);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const blankDays = Array.from({length: firstDayOfMonth}, () => null);
  const days = [...blankDays, ...Array.from({length: daysInMonth}, (_, i) => String(i + 1)?.padStart(2, '0'))];

  const adjustDateToLocal = (utcDate: string, localTimeZone: string = 'UTC') => {
    const facilityTime = moment.utc(utcDate).tz(localTimeZone);
    return facilityTime.format('YYYY-MM-DD HH:mm:ss');
  };

  useEffect(() => {
    const statusFilteredShifts = shiftData.map(shift => ({
      ...shift,
      shifts: shift.shifts.map(innerShift => ({
        ...innerShift,
        startTime: adjustDateToLocal(innerShift.startTime, timeZone || 'UTC'),
        endTime: adjustDateToLocal(innerShift.endTime, timeZone || 'UTC'),
      })),
    }));

    setAdjustedShifts(statusFilteredShifts);
  }, [shiftData, timeZone]);

  const handleOpenDrawer = async (
    mappedShiftsForDay: MappedShiftV2[],
    counts: any,
    selectedDay: string,
    selectedTabFilter?: string,
    count?: number
  ) => {
    if (mappedShiftsForDay.length > 0) {
      const dateOfShift = moment(mappedShiftsForDay[0].startTime).format('dddd MMMM Do');
      setSelectedDate(dateOfShift);
    }
    setDrawerData({
      shifts: mappedShiftsForDay,
      counts,
      currentDate,
      selectedDay,
      selectedTabFilter,
      selectedFilterCount: count,
    });

    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const renderShiftsForDay = (
    counts: any,
    day: string,
    mappedShiftsForDay: MappedShiftV2[],
    renderedShift: MappedShiftV2[],
    unfilteredCounts: any
  ) => {
    const isFutureDate =
      (isNextMonths || (isCurrentMonth && Number(day) >= currentDay)) &&
      (counts.unfilledCount || counts.pendingCount || counts.filledCount || counts.cancelledCount);

    const filteredShiftsCount =
      mappedShiftsForDay.reduce((total, shift) => total + shift.openings.length, 0) -
      renderedShift.reduce((total, shift) => total + shift.openings.length, 0);

    const displayFilteredCount = filteredShiftsCount > 0 && (
      <Text
        fontSize="12px"
        color="#2D3748"
        cursor="pointer"
        _hover={{textDecoration: 'underline'}}
        onClick={e => {
          e.stopPropagation();
          handleOpenDrawer(mappedShiftsForDay, unfilteredCounts, day);
        }}
      >
        {filteredShiftsCount} Filtered Shift{filteredShiftsCount > 1 ? 's' : ''}
      </Text>
    );

    const renderShiftBox = (label: string, count: number, color: string, type: string) => (
      <Box
        padding="0.17rem 0.5rem"
        backgroundColor={color}
        color="#0000007A"
        borderRadius={2}
        width="100%"
        onClick={e => {
          e.stopPropagation();
          handleOpenDrawer(mappedShiftsForDay, unfilteredCounts, day, type, count);
        }}
      >
        {label} -{' '}
        <Text as="span" color="#000000CC" fontWeight={600}>
          {count}
        </Text>
      </Box>
    );

    if (isFutureDate) {
      return (
        <Flex height="calc(100% - 30px)" alignItems="center" justifyContent="flex-start" flexDirection="column">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            padding="5px"
            gap="0.3rem"
            flexDirection="column"
            width="100%"
            sx={{
              fontSize: {base: '18px', md: '12px'},
            }}
            height="calc(100% - 10px)"
            onClick={() => handleOpenDrawer(mappedShiftsForDay, unfilteredCounts, day)}
            overflow="hidden"
          >
            {counts.filledCount > 0 && renderShiftBox('Filled', counts.filledCount, '#5AC68D4D', 'filled')}
            {counts.unfilledCount > 0 && renderShiftBox('Unfilled', counts.unfilledCount, '#f1ddcc', 'unfilled')}
            {counts.pendingCount > 0 && renderShiftBox('Pending', counts.pendingCount, '#2970FF1A', 'pending')}
            {counts.cancelledCount > 0 && renderShiftBox('Cancelled', counts.cancelledCount, '#D9D9D980', 'cancelled')}
          </Flex>
          {displayFilteredCount}
        </Flex>
      );
    }

    if (counts.completedCount || counts.unfilledCount || counts.cancelledCount) {
      return (
        <Flex height="calc(100% - 30px)" alignItems="center" justifyContent="flex-start" flexDirection="column">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            padding="5px"
            gap="0.3rem"
            flexDirection="column"
            width="100%"
            sx={{
              fontSize: {base: '18px', md: '12px'},
            }}
            height="calc(100% - 30px)"
            onClick={() => handleOpenDrawer(mappedShiftsForDay, unfilteredCounts, day)}
          >
            {counts.completedCount > 0 && renderShiftBox('Completed', counts.completedCount, '#5AC68D4D', 'completed')}
            {counts.unfilledCount > 0 && renderShiftBox('Unfilled', counts.unfilledCount, '#f1ddcc', 'unfilled')}
            {counts.cancelledCount > 0 && renderShiftBox('Cancelled', counts.cancelledCount, '#D9D9D980', 'cancelled')}
          </Flex>
          {displayFilteredCount}
        </Flex>
      );
    }

    return (
      <Flex height="calc(100% - 30px)" width="100%" flexDirection="column" alignItems="center" justifyContent="center">
        {filteredShiftsCount > 0 ? (
          <Flex height="100%" alignItems="flex-end" justifyContent="flex-end">
            {displayFilteredCount}
          </Flex>
        ) : (
          <Text
            sx={{
              fontSize: {base: '18px', md: '12px'},
            }}
            color="#2D3748"
            fontWeight="500"
            padding="5px"
          >
            No shift on this day
          </Text>
        )}
      </Flex>
    );
  };

  // for monthly-confirmed view

  const [searchParams] = useSearchParams();
  const pageView = searchParams.get('view');

  useEffect(() => {
    if (pageView === 'monthly-confirmed') {
      trackCalendarMonthlyConfirmedPageView();
      setStatusFilter(filters);
    }
    trackCalendarMonthlyOverviewPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageView]);

  return (
    <Flex className="month-view-container" flexDir="column" height="100%" minWidth={["50vh" , '100%']} overflowY={['scroll' , 'auto']} >
      <Flex
        className="month-range-container"
        justifyContent="space-between"
        alignItems="center"
        height="50px"
        width={{base: '150vh', md: '100%'}}
      >
        <CalenderFilters
          shiftsSummary={sortedShiftsSummary || []}
          setSelectedUnitFilter={setSelectedUnitFilter}
          selectedUnitFilter={selectedUnitFilter}
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
          shifts={shiftData}
          startDate={startDate}
          endDate={endDate}
          isMonthlyFilter
          isMonthlyConfirmedFilter={pageView === 'monthly-confirmed'}
          calenderComponent={
            <Flex className="date-container" alignItems="center">
              <IconButton
                aria-label="Previous Day"
                colorScheme="black"
                variant="ghost"
                icon={<ChevronLeftIcon />}
                onClick={goToPreviousMonth}
              />
              <Box>
                <Text fontSize="large" fontWeight="600">{`${monthNames[month]} ${year}`}</Text>
              </Box>
              <IconButton
                aria-label="Next Day"
                colorScheme="black"
                variant="ghost"
                icon={<ChevronRightIcon />}
                onClick={goToNextMonth}
              />
            </Flex>
          }
        />
      </Flex>

      <MonthlyCalendarAgendaDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        selectedDate={selectedDate}
        shiftDetails={drawerData}
        searchAllShifts={() => searchShifts()}
        displayPostShift={isNextMonths || (isCurrentMonth && Number(drawerData?.selectedDay) >= currentDay)}
      />

      {isLoading || !isDataFetched ? (
        <Flex justifyContent="center" alignItems="center" height="50vh" minWidth="50vh">
          <Spinner />
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          height="calc(100% - 50px)"
          className="monthly-grid"
          width="100%"
          minHeight={["120%", "auto"]}
          overflowY="auto"
          minWidth={['150vh', '100%']}
        >
          <Grid
            className="monthly-grid-header"
            templateColumns={{
              base: 'repeat(7, minmax(250px, 1fr))',
              lg: 'repeat(7, 1fr)',
            }}
            pt="0.5rem"
          >
            {daysOfWeek.map(day => (
              <Flex padding="10px 8px" {...commonFlexProps} height="37px">
                {day}
              </Flex>
            ))}
          </Grid>
          <Grid
            className="monthly-grid-body"
            templateColumns={{
              base: 'repeat(7, minmax(250px, 1fr))',
              lg: 'repeat(7, 1fr)',
            }}
            height="calc(100% - 45px)"
            width="100%"
          >
            {days?.map((day: any) => {
              if (day) {
                const formattedDay = day.padStart(2, '0');
                const calendarDateString = `${year}-${String(month + 1).padStart(2, '0')}-${formattedDay}`;

                const shiftsForDay = adjustedShifts.flatMap(shift =>
                  shift.shifts.filter(innerShift => {
                    const shiftDate = innerShift.startTime.split(' ')[0];
                    return shiftDate === calendarDateString;
                  })
                );

                const unitSummary = getFilteredSummary(adjustedShifts, sortedShiftsSummary)?.map(unit => ({
                  unitId: unit.id,
                  positions: unit.positions.map(position => position.name),
                }));

                const filteredShifts = shiftsForDay
                  ?.map((shift: any) => {
                    const ndPros = shift?.openings?.map((opening: any) => opening?.ndProId);
                    const filteredApplicants = shift?.applicants
                      ?.filter(
                        (applicant: any, index: number, self: any) =>
                          self.findIndex((t: any) => t.ndProId === applicant.ndProId) === index
                      )
                      ?.filter((applicant: {ndProId: any}) => !ndPros?.includes(applicant?.ndProId));

                    const openedOpenings = shift?.openings
                      ? shift.openings.filter((opening: {status: string}) =>
                          ['Opened', 'OpenedWithApplicants'].includes(opening.status)
                        )
                      : [];

                    const updatedOpenings = openedOpenings.map((opening: any, index: number) => {
                      if (filteredApplicants?.length > index) {
                        return {
                          ...opening,
                          status: 'OpenedWithApplicants',
                        };
                      }

                      return {
                        ...opening,
                        status: 'Opened',
                      };
                    });

                    const otherOpenings = shift?.openings
                      ? shift.openings.filter(
                          (opening: any) => !['Opened', 'OpenedWithApplicants'].includes(opening.status)
                        )
                      : [];

                    const filteredOpening = [...otherOpenings, ...updatedOpenings];

                    return {...shift, openings: filteredOpening};
                  })
                  .filter(
                    innerShift =>
                      unitSummary?.find(unit => unit.unitId === innerShift.unitId) &&
                      unitSummary
                        ?.find(unit => unit.unitId === innerShift.unitId)
                        ?.positions?.includes(innerShift?.positionDetail?.[0]?.name)
                  )
                  ?.sort((a, b) => Number(new Date(a.startTime).getTime()) - Number(new Date(b.startTime).getTime()));

                const sortedShifts = [...filteredShifts]
                  .filter(
                    innerShift =>
                      selectedUnitFilter?.find(unit => unit.unitId === innerShift.unitId) &&
                      selectedUnitFilter
                        ?.find(unit => unit.unitId === innerShift.unitId)
                        ?.positions?.includes(innerShift?.positionDetail?.[0]?.name)
                  )
                  .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, timeZone || 'UTC')));

                const counts = getShiftCountsByStatus(sortedShifts);

                const unfilteredShiftCounts = getShiftCountsByStatus(filteredShifts);

                return (
                  <Flex
                    border="1px solid #F2F4F7"
                    flexDirection="column"
                    alignItems="flex-start"
                    _hover={{cursor: 'pointer', background: '#F9FAFB'}}
                    key={day}
                    width="100%"
                    display="block"
                    overflow="hidden"
                    minHeight="100px"
                  >
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                      <Flex
                        background={isCurrentMonth && Number(day) === currentDay ? 'black' : 'white'}
                        color={isCurrentMonth && Number(day) === currentDay ? 'white' : 'black'}
                        height="24px"
                        width="24px"
                        borderRadius="50%"
                        margin="0.2rem"
                        fontSize="14px"
                        {...commonFlexProps}
                      >
                        {day}
                      </Flex>
                      {pageView === 'monthly-confirmed' && (
                        <>
                          <Flex overflow="hidden" padding="2px" alignItems="center" gap="3px" flex="1">
                            {Number(unfilteredShiftCounts?.completedCount) +
                            Number(unfilteredShiftCounts?.filledCount) ? (
                              <Box
                                padding="0 0.3rem"
                                backgroundColor="#5AC68D4D"
                                color="#0000007A"
                                borderRadius={2}
                                title={`Confirmed  - ${
                                  Number(unfilteredShiftCounts?.completedCount) +
                                  Number(unfilteredShiftCounts?.filledCount)
                                }`}
                                onClick={e => {
                                  e?.stopPropagation();
                                  handleOpenDrawer(
                                    filteredShifts,
                                    unfilteredShiftCounts,
                                    day
                                    // 'completed',
                                    // unfilteredShiftCounts.completedCount
                                  );
                                }}
                              >
                                {Number(unfilteredShiftCounts?.completedCount) +
                                  Number(unfilteredShiftCounts?.filledCount)}
                              </Box>
                            ) : null}

                            {unfilteredShiftCounts?.unfilledCount ? (
                              <Box
                                padding="0 0.3rem"
                                backgroundColor="#f1ddcc"
                                color="#0000007A"
                                borderRadius={2}
                                title={`Unfilled  - ${unfilteredShiftCounts?.unfilledCount}`}
                                onClick={e => {
                                  e?.stopPropagation();
                                  handleOpenDrawer(
                                    filteredShifts,
                                    unfilteredShiftCounts,
                                    day,
                                    'unfilled',
                                    unfilteredShiftCounts.unfilledCount
                                  );
                                }}
                              >
                                {unfilteredShiftCounts?.unfilledCount}
                              </Box>
                            ) : null}

                            {unfilteredShiftCounts?.pendingCount ? (
                              <Box
                                padding="0 0.3rem"
                                backgroundColor="#2970FF1A"
                                color="#0000007A"
                                borderRadius={2}
                                title={`Pending  - ${unfilteredShiftCounts?.pendingCount}`}
                                onClick={e => {
                                  e?.stopPropagation();
                                  handleOpenDrawer(
                                    filteredShifts,
                                    unfilteredShiftCounts,
                                    day,
                                    'pending',
                                    unfilteredShiftCounts.pendingCount
                                  );
                                }}
                              >
                                {unfilteredShiftCounts?.pendingCount}
                              </Box>
                            ) : null}

                            {unfilteredShiftCounts?.cancelledCount ? (
                              <Box
                                padding="0 0.3rem"
                                backgroundColor="#D9D9D980"
                                color="#0000007A"
                                borderRadius={2}
                                title={`Cancelled  - ${unfilteredShiftCounts?.cancelledCount}`}
                                onClick={e => {
                                  e?.stopPropagation();
                                  handleOpenDrawer(
                                    filteredShifts,
                                    unfilteredShiftCounts,
                                    day,
                                    'cancelled',
                                    unfilteredShiftCounts.cancelledCount
                                  );
                                }}
                              >
                                {unfilteredShiftCounts?.cancelledCount}
                              </Box>
                            ) : null}
                          </Flex>
                          {(isNextMonths || (isCurrentMonth && Number(day) >= currentDay)) && (
                            <Button
                              color="white"
                              background="#313871"
                              _hover={{background: '#313871'}}
                              onClick={() => navigate(`/shifts/create?d=${calendarDateString}`)}
                              padding="0"
                              marginRight="10px"
                              height="18px"
                              width="18px"
                              minWidth="15px"
                            >
                              <Text fontSize="12px" fontWeight="800">
                                +
                              </Text>
                            </Button>
                          )}
                        </>
                      )}

                      {pageView === 'monthly-overview' &&
                        (isNextMonths || (isCurrentMonth && Number(day) >= currentDay)) && (
                          <Button
                            color="white"
                            background="#313871"
                            _hover={{background: '#313871'}}
                            onClick={() => navigate(`/shifts/create?d=${calendarDateString}`)}
                            padding="0"
                            marginRight="10px"
                            height="18px"
                            width="18px"
                            minWidth="15px"
                          >
                            <Text fontSize="12px" fontWeight="800">
                              +
                            </Text>
                          </Button>
                        )}
                    </Flex>

                    {pageView === 'monthly-overview' ? (
                      renderShiftsForDay(counts, day, filteredShifts, sortedShifts, unfilteredShiftCounts)
                    ) : (
                      <MonthlyConfirmedShiftsListCard
                        counts={unfilteredShiftCounts}
                        day={day}
                        mappedShiftsForDay={filteredShifts}
                        handleOpenDrawer={handleOpenDrawer}
                        selectedUnitFilter={selectedUnitFilter}
                      />
                    )}
                  </Flex>
                );
              }

              return (
                <Flex height="100%" {...commonFlexProps}>
                  {' '}
                </Flex>
              );
            })}
          </Grid>
        </Flex>
      )}
    </Flex>
  );
};

export default MonthlyAgendaView;
