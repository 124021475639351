/* eslint-disable no-underscore-dangle */
import {
  Button,
  Center,
  Flex,
  FormLabel,
  Icon,
  Input,
  Spinner,
  Switch,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {LiaInfoCircleSolid} from 'react-icons/lia';
// import {MinusIcon} from '@chakra-ui/icons';
import useAppSelector from '../../shared/hooks/useAppSelector';
import schema from './utils/schema';

// GQL query and mutation
import useUpdateLagacySettings from '../../shared/gql/Settings/mutation';
import useGetLegacyFacilityDataById from '../../shared/gql/Settings/queries';

// components
// import FileInput from '../../shared/components/FileInput/FileInput';
import NDReactSelect from '../../shared/components/NDReactSelect/NDReactSelect';

// Types
import {FormData} from './Setting.types';
import {SingleValue} from '../ShiftTemplates/ShiftTemplates.types';

// constants
import {covidStatusOption} from './constants';
import {useLegacyFacilityId} from '../Shifts/CreateShift/appSelectors';
import {trackSettingsPageView, trackUpdateSettingsEvent} from '../../shared/mixpanel/page-views/pages/othermodules';

const Settings = () => {
  const toast = useToast();

  const legacyFacilityId = useLegacyFacilityId();

  const [file, setFile] = useState({
    url: '',
    fileName: '',
    base64String: '',
    isRemoved: false,
  });

  const {data: settingsData} = useAppSelector(state => state.settings);

  const {
    covidMedicalExemption,
    covidReligiousExemption,
    covidStatus,
    covidVaccineRequired,
    allowSingleDoseVaccine,
    orientationMaterials,
  } = useMemo(() => settingsData, [settingsData]);

  const loggedInUserFacilityId = Number(legacyFacilityId);

  const {data: facilityData, loading} = useGetLegacyFacilityDataById({variables: {id: loggedInUserFacilityId}});

  const {
    register,
    control,
    watch,
    resetField,
    getValues,
    setValue,
    handleSubmit,
    formState: {errors, dirtyFields, isSubmitting: formIsSummitting},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const formIsDirty = Object.keys(dirtyFields).length !== 0;

  // const onFileInputChange = (data: any) => {
  //   setFile({url: data?.blobUrl, base64String: data?.base64String, fileName: data?.fileName, isRemoved: false});
  // };

  useEffect(() => {
    trackSettingsPageView();
  }, []);

  const settingPayload = {
    ...settingsData,
    id: loggedInUserFacilityId,
    orientationMaterials:
      file?.base64String !== '' || file?.isRemoved
        ? {
            data: file?.base64String,
            title: file?.fileName,
          }
        : orientationMaterials,
    surveyUsername: `${getValues('surveyUsername')}`,
    surveyUseremail: `${getValues('surveyUseremail')}`,
    surveyUsertitle: `${getValues('surveyUsertitle')}`,
    covidStatus: `${getValues('covidStatus')?.value}` || '',
    covidVaccineRequired: getValues('covidVaccineRequired'),
    allowSingleDoseVaccine: getValues('allowSingleDoseVaccine'),
    covidMedicalExemption: getValues('covidMedicalExemption'),
    covidReligiousExemption: getValues('covidReligiousExemption'),
  };

  const [updateSettingsFn, {loading: isSubmitting}] = useUpdateLagacySettings({
    variables: settingPayload,
  });

  const onSubmit = async () => {
    try {
      const settingResponse = await updateSettingsFn({variables: settingPayload});

      toast({
        title: settingResponse?.data ? 'Settings updated successfully.' : 'Failed to update settings.',
        status: settingResponse?.data ? 'success' : 'error',
        duration: 3000,
        isClosable: true,
      });

      if (settingResponse?.data) {
        resetField('surveyUseremail');
        resetField('surveyUsername');
        resetField('surveyUsertitle');

        trackUpdateSettingsEvent(settingPayload);
      }
    } catch (error) {
      console.error('Failed to add message', error);
    }
  };

  useEffect(() => {
    if (facilityData && settingsData) {
      setFile({
        url: orientationMaterials?.src ?? '',
        fileName: orientationMaterials?.title ?? '',
        base64String: '',
        isRemoved: false,
      });
      setValue('covidMedicalExemption', covidMedicalExemption);
      setValue('covidReligiousExemption', covidReligiousExemption);
      setValue('allowSingleDoseVaccine', allowSingleDoseVaccine);
      setValue(
        'covidStatus',
        covidStatusOption?.find(status => status?.value === covidStatus) || {value: '', label: ''}
      );
      setValue('covidVaccineRequired', covidVaccineRequired);
    }
  }, [
    allowSingleDoseVaccine,
    covidMedicalExemption,
    covidReligiousExemption,
    covidStatus,
    covidVaccineRequired,
    facilityData,
    orientationMaterials?.src,
    orientationMaterials?.title,
    setValue,
    settingsData,
  ]);

  const minWidthValue = useBreakpointValue({
    base: '50vh',
    sm: '150%',
    md: '50%',
    lg: '100%',
    xl: '100%',
  });

  return (
    <Center minWidth={minWidthValue}>
      {loading || !facilityData ? (
        <Flex margin="0" position="absolute" top="50%" left={["auto","50%"]} transform="translate(-50%, -50%)">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#38427E" size="xl" />
        </Flex>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Flex
            flexDirection="column"
            marginTop={['1rem', 'inherit']}
            padding="2rem"
            border="1px solid #e2e8f0"
            gap="1rem"
          >
            {/* <FileInput
              onChange={onFileInputChange}
              inputName="orientationMaterials"
              inputText="Drop your pdf file here"
              fileExtensions={['pdf']}
              errorMessage="Only Pdf files are allowed."
            /> */}

            <Flex flexDirection="column" gap=".5rem" bg="#FFFFFF">
              <FormLabel fontWeight="400" mb="0">
                Name
              </FormLabel>
              <Input {...register('surveyUsername')} placeholder="What is your name?" _disabled={{opacity: 0.6}} />
              {errors?.surveyUsername && (
                <Text color="red.500" fontSize="sm">
                  {errors?.surveyUsername?.message}
                </Text>
              )}
            </Flex>

            <Flex flexDirection="column" gap=".5rem" bg="#FFFFFF">
              <FormLabel fontWeight="400" mb="0">
                Email
              </FormLabel>
              <Input
                {...register('surveyUseremail')}
                placeholder="Please type your email address"
                _disabled={{opacity: 0.6}}
              />
              {errors?.surveyUseremail && (
                <Text color="red.500" fontSize="sm">
                  {errors?.surveyUseremail?.message}
                </Text>
              )}
            </Flex>

            <Flex flexDirection="column" gap=".5rem" bg="#FFFFFF">
              <FormLabel fontWeight="400" mb="0">
                Title
              </FormLabel>
              <Input {...register('surveyUsertitle')} placeholder="What is your title?" _disabled={{opacity: 0.6}} />
              {errors?.surveyUsertitle && (
                <Text color="red.500" fontSize="sm">
                  {errors?.surveyUsertitle?.message}
                </Text>
              )}
            </Flex>

            <Flex flexDirection="column" gap=".5rem" bg="#FFFFFF" paddingBottom="1rem">
              <Flex flexDirection="row" alignItems="center">
                <FormLabel fontWeight="400" mb="0">
                  Covid Status
                </FormLabel>
                <Tooltip
                  label="COVID Statuses: 
                  COVID Free - No known patients or residents are COVID positive. 
                  COVID On-Site - At least 1 patient or resident has tested positive with COVID in the last 7 days and has not have a negative test since then."
                  placement="top"
                >
                  <Flex>
                    <Icon as={LiaInfoCircleSolid} boxSize="1.2rem" />
                  </Flex>
                </Tooltip>
              </Flex>

              <Controller
                name="covidStatus"
                control={control}
                render={({field}) => (
                  <NDReactSelect
                    {...register('covidStatus')}
                    {...field}
                    isMulti={false}
                    placeholder="Select Status"
                    options={covidStatusOption}
                    onChange={(e: SingleValue) => setValue('covidStatus', e)}
                    closeMenuOnSelect
                  />
                )}
              />
              {errors?.covidStatus && (
                <Text color="red.500" fontSize="sm">
                  {errors?.covidStatus?.message}
                </Text>
              )}
            </Flex>

            <Flex flexDirection="row" gap=".5rem" bg="#FFFFFF">
              <Switch
                id="covidVaccineRequired"
                {...register('covidVaccineRequired')}
                isChecked={watch('covidVaccineRequired')}
                onChange={e => setValue('covidVaccineRequired', e?.target?.checked)}
              />
              <FormLabel fontWeight="400" mb="0">
                Does your facility require that all staff members be vaccinated for Covid-19?
              </FormLabel>
            </Flex>

            {watch('covidVaccineRequired') && (
              <>
                <Flex flexDirection="row" gap=".5rem" bg="#FFFFFF">
                  <Switch
                    id="allowSingleDoseVaccine"
                    {...register('allowSingleDoseVaccine')}
                    isChecked={watch('allowSingleDoseVaccine')}
                    onChange={e => setValue('allowSingleDoseVaccine', e?.target?.checked)}
                  />
                  <FormLabel fontWeight="400" mb="0">
                    Allow Single Dose Vaccinations
                  </FormLabel>
                </Flex>

                <Flex flexDirection="row" gap=".5rem" bg="#FFFFFF">
                  <Switch
                    id="covidMedicalExemption"
                    {...register('covidMedicalExemption')}
                    isChecked={watch('covidMedicalExemption')}
                    onChange={e => setValue('covidMedicalExemption', e?.target?.checked)}
                  />
                  <FormLabel fontWeight="400" mb="0">
                    Allow Medical COVID Vaccine Exemption
                  </FormLabel>
                </Flex>

                <Flex flexDirection="row" gap=".5rem" bg="#FFFFFF" paddingBottom="1rem">
                  <Switch
                    id="covidReligiousExemption"
                    {...register('covidReligiousExemption')}
                    isChecked={watch('covidReligiousExemption')}
                    onChange={e => setValue('covidReligiousExemption', e?.target?.checked)}
                  />
                  <FormLabel fontWeight="400" mb="0">
                    Allow Religious COVID Vaccine Exemption
                  </FormLabel>
                </Flex>
              </>
            )}

            {file?.fileName !== '' && (
              <Flex gap="0.5rem" flexDirection="column" paddingBottom="1rem">
                {/* <Flex
                  justifyContent="center"
                  alignItems="center"
                  style={{background: '#718096', height: 20, width: 20, borderRadius: 10}}
                  onClick={() => {
                    setFile({url: '', fileName: '', base64String: '', isRemoved: true});
                  }}
                >
                  <MinusIcon />
                </Flex> */}
                <Text>Orientation materials</Text>
                <a href={file?.url} target="_blank" rel="noreferrer">
                  <Text fontWeight="700" mb="0" color="#313971" textDecoration="underline">
                    {file?.fileName}
                  </Text>{' '}
                </a>
              </Flex>
            )}

            <Button
              type="submit"
              h="3rem"
              w={['60%', '6rem']}
              isDisabled={!formIsDirty || formIsSummitting}
              isLoading={isSubmitting}
              loadingText="Saving"
            >
              Save
            </Button>
          </Flex>
        </form>
      )}
    </Center>
  );
};

export default Settings;
