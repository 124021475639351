/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
import React, {useEffect} from 'react';

import {Controller, FieldValues, useForm} from 'react-hook-form';

import {MdFilterList} from 'react-icons/md';
import {SearchIcon} from '@chakra-ui/icons';
import {Box, Flex, Input, InputGroup, InputRightElement, IconButton, useToast, Grid, Skeleton} from '@chakra-ui/react';
import uuid from 'react-uuid';
import useAppSelector from '../../shared/hooks/useAppSelector';
import useAppDispatch from '../../shared/hooks/useAppDispatch';
import NDReactSelect from '../../shared/components/NDReactSelect/NDReactSelect';
import TemplateForm from './TemplateForm/TemplateForm';
import useGetShiftTemplateByFacilityId, {useGetFacilityUnitPositionRate} from '../../shared/gql/shiftTemplates/queries';
import {
  setShiftTemplate,
  newTemplateClicked,
  ShiftTemplateState,
  ShiftTemplate,
} from '../../shared/slices/shiftTemplateSlice';
import {AuthState} from '../../shared/slices/authSlice';
import {SidebarState} from '../../shared/slices/sidebarSlice';
import {ExampleState} from '../../shared/slices/testSlice';
import {SingleValue, UniquePositions} from './ShiftTemplates.types';
import {useFacilityId} from '../Shifts/CreateShift/appSelectors';

import Header from '../../shared/components/Header/Header';
import ShiftTemplateCard from './ShiftTemplateCard/ShiftTemplateCard';
import Info from '../../shared/components/Info/Info';
import {trackShiftTemplatesListView} from '../../shared/mixpanel/page-views';
import {
  trackShiftTemplatePositionFilter,
  trackShiftTemplateSearchFilter,
  trackShiftTemplateUnitFilter,
} from '../../shared/mixpanel/events/shiftTemplate';

const ShiftTemplates: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [applyFilters, setApplyFilters] = React.useState(false);

  const {handleSubmit, register, control, watch, setValue, reset} = useForm();

  const dispatch = useAppDispatch();
  const facilityId = useFacilityId();

  useGetFacilityUnitPositionRate({variables: {facilityId}});
  const {data, error, loading} = useGetShiftTemplateByFacilityId({variables: {facilityId}});

  const shiftTemplatesState = useAppSelector(
    (state: {auth: AuthState; sidebar: SidebarState; shiftTemplates: ShiftTemplateState; example: ExampleState}) =>
      state.shiftTemplates.data
  );

  //  todo: figure out why getFacilityUnitPositionRate does not exist on facilityUnits
  const facilityUnitPositionRateData = useAppSelector(
    (state: any) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );

  const handleFilterClick = () => {
    setApplyFilters(!applyFilters);
  };

  const handleIsNewTemplateModalOpen = () => {
    setIsModalOpen(!isModalOpen);
    dispatch(newTemplateClicked());
  };

  const handleUnitChange = (selectedOption: SingleValue) => {
    setValue('unit', selectedOption);
    trackShiftTemplateUnitFilter(selectedOption);
  };

  const handlePositionChange = (selectedOption: SingleValue) => {
    setValue('position', selectedOption);
    trackShiftTemplatePositionFilter(selectedOption);
  };

  const currentUnits = watch('unit')?.map((unit: any) => unit.value);

  const filterByUnit =
    currentUnits && shiftTemplatesState.filter((template: ShiftTemplate) => currentUnits.includes(template.unitId));

  const isFilteredByUnit = !!(filterByUnit && currentUnits.length);

  const uniquePositions = [
    ...new Set(
      shiftTemplatesState.flatMap((template: ShiftTemplate) =>
        template.content.positions?.map((pos: UniquePositions) => pos.name)
      )
    ),
  ];

  const positionOptions = uniquePositions?.map((pos: string) => ({value: pos, label: pos}));

  const currentPosition = watch('position')?.map((pos: any) => pos.value);
  const filterByPosition = shiftTemplatesState.filter(
    (template: ShiftTemplate) =>
      template.content.positions &&
      template.content.positions.some(positionObj => currentPosition?.includes(positionObj.name))
  );

  const isFilteredByPosition = !!(filterByPosition && currentPosition?.length);

  const isFilteredByUnitAndPosition = !!(
    currentUnits &&
    currentUnits.length &&
    currentPosition &&
    currentPosition.length
  );

  const filterByUnitAndPosition =
    isFilteredByUnitAndPosition &&
    filterByUnit.filter(
      (template: ShiftTemplate) =>
        template.content.positions &&
        template.content.positions.some(positionObj => currentPosition?.includes(positionObj.name))
    );

  const currentSearch = watch('search');
  const lowerCaseSearch = currentSearch?.toLowerCase();

  const filterBySearch = isFilteredByUnitAndPosition
    ? filterByUnitAndPosition.filter((template: ShiftTemplate) => template.name.toLowerCase().includes(lowerCaseSearch))
    : isFilteredByPosition
    ? filterByPosition.filter((template: ShiftTemplate) => template.name.toLowerCase().includes(lowerCaseSearch))
    : isFilteredByUnit
    ? filterByUnit.filter((template: ShiftTemplate) => template.name.toLowerCase().includes(lowerCaseSearch))
    : shiftTemplatesState.filter((template: ShiftTemplate) => template.name.toLowerCase().includes(lowerCaseSearch));

  const isFilteredBySearch = currentSearch !== '';

  const templates = isFilteredBySearch
    ? filterBySearch
    : isFilteredByUnitAndPosition
    ? filterByUnitAndPosition
    : isFilteredByUnit
    ? filterByUnit
    : isFilteredByPosition
    ? filterByPosition
    : shiftTemplatesState;

  useEffect(() => {
    trackShiftTemplateSearchFilter(currentSearch);
  }, [currentSearch]);

  const handleCardClick = (template: ShiftTemplate) => {
    setIsModalOpen(true);
    dispatch(setShiftTemplate(template));
  };

  const onSubmit = (formData: FieldValues) => {
    console.log('formData: ', formData); //  TODO: remove/comment
  };

  const toast = useToast();

  useEffect(() => {
    if (!loading && facilityId && error) {
      toast({
        title: 'Error',
        description: 'There was an error loading your shift templates.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, facilityId, loading, toast]);

  useEffect(() => {
    trackShiftTemplatesListView();
  }, []);

  return (
    <Box paddingBottom="1rem" minWidth="50vh">
      {isModalOpen && (
        <TemplateForm
          getTemplates={() => console.log('test')}
          toast={toast}
          reset={reset}
          handleIsNewTemplateModalOpen={handleIsNewTemplateModalOpen}
        />
      )}
      {/* Header */}
      <Header
        title="Shift Templates"
        subTitle="Save your commonly posted shifts into a template. With templates, your next shift posting is only a single click away."
        btnText="New Template"
        onClick={() => {
          handleIsNewTemplateModalOpen();
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap="1rem" marginBottom="1rem" display="flex" flexDirection={['column', 'row']}>
          <Flex marginBottom=".5rem" gap=".5rem" width={['100%', '46%']}>
            <InputGroup>
              <InputRightElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input {...register('search')} placeholder="Search shifts" defaultValue={undefined} />
            </InputGroup>
            <IconButton
              aria-label="Search database"
              icon={<MdFilterList />}
              variant="secondary"
              onClick={handleFilterClick}
              display={['inherit', 'none']}
            />
          </Flex>
          <Box width={['100%', '27%']} display={[applyFilters ? '' : 'none', 'block']}>
            <Controller
              name="unit"
              control={control}
              render={({field}) => (
                <NDReactSelect
                  {...register('unit')}
                  {...field}
                  isClearable
                  isMulti
                  placeholder="Unit"
                  options={facilityUnitPositionRateData?.map((unit: UniquePositions) => ({
                    value: unit.id,
                    label: unit.name,
                  }))}
                  onChange={(e: SingleValue) => handleUnitChange(e)}
                />
              )}
            />
          </Box>
          <Box width={['100%', '27%']} display={[applyFilters ? '' : 'none', 'block']}>
            <Controller
              name="position"
              control={control}
              render={({field}) => (
                <NDReactSelect
                  {...register('position')}
                  {...field}
                  isClearable
                  placeholder="Position"
                  onChange={(e: SingleValue) => (!e ? setValue('position', '') : handlePositionChange(e))}
                  isMulti
                  options={positionOptions}
                />
              )}
            />
          </Box>
        </Flex>
        {data && templates?.length === 0 && <Info handleIsNewTemplateModalOpen={handleIsNewTemplateModalOpen} />}
        {/* Cards */}
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(3,1fr)']} gap={4}>
          {loading
            ? Array.from({length: 12}).map(() => <Skeleton key={uuid()} height="12.5rem" width="100%" />)
            : [...templates]
                ?.reverse()
                ?.map((template: ShiftTemplate) => (
                  <ShiftTemplateCard
                    onClick={() => handleCardClick(template)}
                    key={uuid()}
                    templateName={template.name}
                    position={template.content.positions}
                    bonus={template.content.bonusAmount}
                    specificDay={template.content.openings[0]?.dayOfWeek}
                    startTime={template.content.startTime}
                    endTime={template.content.endTime}
                    qualifications={template.content.qualifications}
                    numOfOpenings={template.content.openings[0]?.total}
                    unit={template.content.unit.name}
                    templateId={template.id}
                  />
                ))}
        </Grid>
      </form>
    </Box>
  );
};

export default ShiftTemplates;
