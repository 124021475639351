import React, {useState} from 'react';
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import {CloseIcon, SearchIcon} from '@chakra-ui/icons';
import {MdKeyboardArrowDown} from 'react-icons/md';
import FontAwesomeImage from '../../../../assets/images/FacilitySelectorTestImage.png';
import './FacilitySelector.scss';
import useAppSelector from '../../../hooks/useAppSelector';
import {ReduxState} from '../../../types/index';
import useSetDefaultFacility from '../../../gql/Facility/mutation';
import {useLazyGetAllFacilities} from '../../../gql/Facility/queries';

type Props = {
  isIcon?: boolean;
};

type FacilityDetails = {
  facilityId: string;
  facilityName: string;
  isDefault: boolean;
  legacyFacilityId: string;
  role: string;
};

const FacilityMenuItem = ({
  facility,
  onSelectFacility,
  onSetDefaultClick,
}: {
  facility: FacilityDetails;
  onSelectFacility: (facility: FacilityDetails) => void;
  onSetDefaultClick: (facility: FacilityDetails) => void;
}) => (
  <MenuItem height="3rem" onClick={() => onSelectFacility(facility)} width="100%">
    <Flex alignItems="center" justifyContent="space-between" gap="3rem" width="100%">
      <Flex alignItems="center" gap="0.2rem" width="calc(100% - 6rem)">
        <img className="facility-selector__icon" src={FontAwesomeImage} alt="Building Icon" />
        <Text color="var(--gray-gray-600, #52525B)" fontSize="14px" fontWeight="600">
          {facility.facilityName}
        </Text>
      </Flex>
      <Button
        padding="2px 10px"
        height="1.5rem"
        width="6rem"
        fontSize="10px"
        onClick={e => {
          e?.stopPropagation();
          onSetDefaultClick(facility);
        }}
        isDisabled={facility.isDefault}
      >
        <Text>{facility.isDefault ? 'Default' : 'Set as default'}</Text>
      </Button>
    </Flex>
  </MenuItem>
);

const FacilitySelector = ({isIcon}: Props) => {
  const sessionUser = useAppSelector((state: ReduxState) => state.usersData.sessionUser);
  const facilityName = sessionUser?.facilityName ?? '';
  const toast = useToast();
  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.canAssumeIdentity
  );
  const [searchQuery, setSearchQuery] = useState('');

  const {getAllFacilities, data} = useLazyGetAllFacilities();

  const [isOpen, setIsOpen] = useState(false);

  const [setDefaultFacilityMutation] = useSetDefaultFacility();

  const onSelectFacility = (facilityDetails: {
    facilityId: string;
    facilityName: string;
    isDefault: boolean;
    legacyFacilityId: string;
    role: string;
  }) => {
    localStorage.setItem('legacyFidParam', facilityDetails?.legacyFacilityId?.toString());
    window.location.href = `${window.location.origin}/home`;
    setIsOpen(false);
  };

  const onSetDefaultClick = async (facility: {
    facilityId: string;
    facilityName: string;
    isDefault: boolean;
    legacyFacilityId: string;
    role: string;
  }) => {
    const payload = {
      facilityId: facility?.facilityId,
      userId: sessionUser?.userId,
    };
    const setDefaultFacilityResponse = await setDefaultFacilityMutation({
      variables: {userDefaultFacilityInput: payload},
    });
    if (setDefaultFacilityResponse.data) {
      toast({
        title: 'Success',
        description: `Setting ${facility?.facilityName} as Default facility successfully!`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setTimeout(() => {
        localStorage.setItem('legacyFidParam', facility?.legacyFacilityId?.toString());
        window.location.href = `${window.location.origin}/home`;
        setIsOpen(false);
      }, 2000);
    } else if (!setDefaultFacilityResponse.data) {
      toast({
        title: 'Error',
        description: `${setDefaultFacilityResponse?.errors || 'Something went wrong!'}  `,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const facilityList = sessionUser?.access?.filter(facility => facility?.facilityId !== sessionUser?.nextGenFacilityId);

  return (
    <div className="facility-selector">
      <Menu onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <MenuButton
          disabled={!isInternalAdmin && facilityList?.length === 0}
          onClick={() => setIsOpen(true)}
          _hover={{
            bg: isInternalAdmin || facilityList?.length !== 0 ? '#E8EAF1' : '',
            border: isInternalAdmin || facilityList?.length !== 0 ? '2px solid #C5CADE' : '',
          }}
          p="0.5rem 0"
          marginRight="0.5rem"
          borderRadius="lg"
        >
          <Flex alignItems="center" gap="0.5rem" margin="0 0.5rem">
            {isIcon && <img className="facility-selector__icon" src={FontAwesomeImage} alt="Building Icon" />}
            {sessionUser?.userId && (
              <Text color="var(--gray-gray-600, #52525B)" fontSize="14px" fontWeight="600">
                {facilityName ? `${facilityName}` : ' '}
              </Text>
            )}
            {(isInternalAdmin || facilityList?.length !== 0) && <MdKeyboardArrowDown />}
          </Flex>
        </MenuButton>
        <MenuList minWidth="unset" width="100%">
          {!isInternalAdmin ? (
            facilityList?.map(facility => (
              <FacilityMenuItem
                key={facility.facilityId}
                facility={facility}
                onSelectFacility={onSelectFacility}
                onSetDefaultClick={onSetDefaultClick}
              />
            ))
          ) : (
            <Flex flexDir="column">
              <Tabs isFitted variant="enclosed" defaultIndex={1}>
                {facilityList && facilityList?.length > 0 && (
                  <TabList>
                    <Tab>
                      <Text fontSize="14px" fontWeight="600">
                        My Facilities
                      </Text>
                    </Tab>
                    <Tab>
                      <Text fontSize="14px" fontWeight="600">
                        BD Facility Search
                      </Text>
                    </Tab>
                  </TabList>
                )}
                <TabPanels>
                  <TabPanel>
                    {facilityList?.map(facility => (
                      <FacilityMenuItem
                        key={facility.facilityId}
                        facility={facility}
                        onSelectFacility={onSelectFacility}
                        onSetDefaultClick={onSetDefaultClick}
                      />
                    ))}
                  </TabPanel>
                  <TabPanel>
                    <Flex gap={['1rem', '1rem']} alignItems="flex-end" height="3rem" width="100%">
                      <InputGroup>
                        <Input
                          onClick={e => e?.stopPropagation()}
                          value={searchQuery}
                          onChange={e => {
                            e?.stopPropagation();
                            setSearchQuery(e?.target?.value);
                            getAllFacilities({
                              variables: {
                                filter: {name__iLike: e?.target?.value},
                              },
                              fetchPolicy: 'no-cache',
                            });
                          }}
                          placeholder="Search facility"
                        />
                        <InputRightElement>
                          <SearchIcon color="#718096" display={searchQuery ? 'none' : 'inherit'} />
                          {searchQuery && (
                            <IconButton
                              size="xsm"
                              color="#718096"
                              variant="ghost"
                              icon={<CloseIcon boxSize=".8rem" />}
                              aria-label="Clear"
                              onClick={e => {
                                e?.stopPropagation();
                                setSearchQuery('');
                              }}
                            />
                          )}
                        </InputRightElement>
                      </InputGroup>
                    </Flex>
                    <Flex
                      overflowY="auto"
                      overflowX="hidden"
                      maxHeight="400px"
                      minWidth="400px"
                      flexDirection="column"
                      marginRight="-1rem"
                    >
                      {searchQuery &&
                        data?.allFacilities?.map((facility: any) => (
                          <MenuItem
                            key={facility?.legacyFacilityId}
                            height="3rem"
                            onClick={() => onSelectFacility({...facility, legacyFacilityId: facility?.id})}
                            width="100%"
                          >
                            <Flex alignItems="center" justifyContent="space-between" gap="3rem" width="100%">
                              <Flex alignItems="center" gap="0.2rem" width="calc(100% - 6rem)">
                                <img className="facility-selector__icon" src={FontAwesomeImage} alt="Building Icon" />
                                <Text color="var(--gray-gray-600, #52525B)" fontSize="14px" fontWeight="600">
                                  {facility?.name}
                                </Text>
                              </Flex>
                            </Flex>
                          </MenuItem>
                        ))}
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          )}
        </MenuList>
      </Menu>
    </div>
  );
};

export default FacilitySelector;
