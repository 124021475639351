import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {Flex, Spinner, useBreakpointValue} from '@chakra-ui/react';

import moment from 'moment';

import {AuthState} from '../../../shared/slices/authSlice';
import {ShiftsState} from '../../../shared/slices/calShiftSlice';

// import {useGetSummaryOfShiftUnits} from '../../../shared/gql/CalendarShifts/queries';

import {useSearchShifts} from '../../../shared/gql/CalendarShifts/mutations';
import useAppSelector from '../../../shared/hooks/useAppSelector';
import {useFacilityId} from '../CreateShift/appSelectors';

// import CalendarCounterCard from '../../../shared/components/CounterCard/CalendarCounterCard';
import CalendarViewDatePicker from '../../../shared/components/DatePicker/CalendarViewDatePicker';
import CalendarTable from '../../../shared/components/CalendarView/CalendarTable';
import {ReduxState} from '../../../shared/types/index';

import {SearchShiftsPayload, Shift, UnitPosition} from '../Shift.types';
import './CalendarPage.scss';
import {endOfDay, processShifts, startOfDay} from './helpers/helpers';
import CalenderFilters from './CalenderFilters';
// import useURLParamsUpdate from '../../../shared/hooks/useURLParamsUpdate';
// import useQueryParams from '../../../shared/hooks/useQueryParams';
import {useGetFacilityUnitPositionRate} from '../../../shared/gql/shiftTemplates/queries';
import {trackCalendarWeeklyPageView} from '../../../shared/mixpanel/page-views';

const today = new Date();
const dayOfWeek = today.getDay();
const diff = dayOfWeek === 0 ? today.getDate() - 7 : today.getDate() - dayOfWeek;
const lastSunday = new Date(today.setDate(diff));

const nextSaturday = new Date(lastSunday);
nextSaturday.setDate(lastSunday.getDate() + 6);

const CalendarPage = () => {
  const facilityUnits = useAppSelector(
    (state: ReduxState) => state.shiftTemplates.facilityUnits?.getFacilityUnitPositionRate.units
  );
  const facilityId = useFacilityId();
  const editedShift = useAppSelector((state: ReduxState) => state?.shifts?.editedShift?.updateShiftOpening);
  const authState = useAppSelector((state: {auth: AuthState; shifts: ShiftsState}) => state.auth);
  // const params = useQueryParams();

  // const dateFromParams = params?.date;

  // const paramsDate = dateFromParams ? new Date(dateFromParams) : new Date();
  // const dayOfWeekParams = paramsDate.getDay();
  // const paramsDiff = dayOfWeek === 0 ? paramsDate.getDate() - 7 : paramsDate.getDate() - dayOfWeekParams;
  // const startDateFromParams = new Date(paramsDate.setDate(paramsDiff));

  // const endDateFromParams = new Date(startDateFromParams);
  // endDateFromParams.setDate(startDateFromParams.getDate() + 6);

  const location = useLocation();
  const startDateFromEdit = location.state ? location.state.prevStartDate : null;
  const prevStartDate = startDateFromEdit ? new Date(JSON.parse(startDateFromEdit)) : null;
  const endDateFromEdit = location.state ? location.state.prevEndDate : null;
  const prevEndDate = endDateFromEdit ? new Date(JSON.parse(endDateFromEdit)) : null;

  const [startDate, setStartDate] = useState<Date>(prevStartDate || lastSunday);
  const [endDate, setEndDate] = useState<Date>(prevEndDate || nextSaturday);

  const [shiftData, setShiftData] = useState<Shift[]>([]);
  const [sortedShiftsSummary, setSortedShiftsSummary] = useState<UnitPosition[]>([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isReadyForSearch, setIsReadyForSearch] = useState(false);

  const convertedStartDate = `${moment(startOfDay(startDate)).format('YYYY-MM-DDTHH:mm:ss')}Z`;
  const convertedEndDate = `${moment(endOfDay(endDate)).format('YYYY-MM-DDTHH:mm:ss')}Z`;

  // const paramsStatus = params?.status ? params?.status?.split(',') : null;

  const filters = ['actionRequired', 'filledCompletely', 'filledPartially', 'upcomingUnfilled'];
  const [statusFilter, setStatusFilter] = useState(filters);

  // const paramsUnit = params?.unit ? params?.unit?.split(',') : null;

  const [selectedUnitFilter, setSelectedUnitFilter] = useState<{unitId: string; positions: string[]}[]>([]);

  // useURLParamsUpdate({
  //   view: 'weekly',
  //   date: `${moment.tz(startOfDay(startDate), 'UTC').format('YYYY-MM-DDTHH:mm:ss')}Z`,
  //   status: statusFilter,
  // });

  const [searchShiftsMutation] = useSearchShifts();

  const {data} = useGetFacilityUnitPositionRate({variables: {facilityId}});
  // all status : 'actionRequired', 'filledCompletely', 'filledPartially', 'upcomingUnfilled' , 'cancelled' and 'pastUnfilled'

  const searchShifts = async () => {
    setIsLoading(true);
    const isMounted = true;

    try {
      const searchShiftsPayload: SearchShiftsPayload = {
        facilityId,
        startDateTime: convertedStartDate,
        endDateTime: convertedEndDate,
      };

      const {data: searchData} = await searchShiftsMutation({
        variables: {
          searchShift: searchShiftsPayload,
        },
      });

      if (isMounted) {
        const fetchedShiftData = searchData?.searchShift?.map(shift => processShifts(shift)) || [];
        setShiftData(fetchedShiftData);
        setIsDataFetched(true);
      }
    } catch (error) {
      console.error('Failed to Search for Shifts', error);
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const readyForSearch = Boolean(authState.token && facilityId);
    setIsReadyForSearch(readyForSearch);
  }, [authState.token, facilityId, data]);

  useEffect(() => {
    // Check if the component is ready for search and is still mounted
    if (isReadyForSearch) {
      searchShifts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForSearch, convertedStartDate, convertedEndDate]);

  useEffect(() => {
    if (facilityUnits) {
      const sorted = [...facilityUnits]?.sort((a, b) => a.name.localeCompare(b.name));
      setSortedShiftsSummary(sorted);
      // if (!selectedUnitFilter?.length) {
      //   setSelectedUnitFilter(
      //     sorted?.map(unit => ({
      //       unitId: unit.id,
      //       positions: unit.positions.map(position => position.id),
      //     }))
      //   );
      // }
      // setSelectedUnitName('All');
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityUnits]);

  useEffect(() => {
    if (editedShift && !isFirstRender) {
      const {unitId, startDateTime} = editedShift;
      const unitName = facilityUnits?.find((shift: UnitPosition) => shift.id.toString() === unitId.toString())?.name;

      const start = new Date(startDateTime);

      const weekDay = start.getDay();

      const newStartDate = new Date(start);
      newStartDate.setDate(start.getDate() - weekDay);

      const newEndDate = new Date(newStartDate);
      newEndDate.setDate(newStartDate.getDate() + 6);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      if (unitName) {
        setSelectedUnitFilter(unitId);
        // setSelectedUnitName(unitName);
      }
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedShift]);

  useEffect(() => {
    trackCalendarWeeklyPageView();
  }, []);

  const minWidth = useBreakpointValue({
    base: "50vh", 
    md: "100%",   
  });

  return (
    <Flex className="boxclass" flexDir="column" height="100%" minWidth={minWidth} overflowY={['scroll', 'unset']}>
      <Flex className="boxclass"
        justifyContent="space-between"
        alignItems="center"
        width={{base: '150vh', md: '100%'}}>
      <CalenderFilters
        shiftsSummary={sortedShiftsSummary}
        setSelectedUnitFilter={setSelectedUnitFilter}
        selectedUnitFilter={selectedUnitFilter}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        shifts={shiftData}
        startDate={startDate}
        endDate={endDate}
        calenderComponent={
          <CalendarViewDatePicker
            startDate={startDate}
            endDate={endDate}
            setDate={setStartDate}
            setEndDate={setEndDate}
          />
        }
      />
      </Flex>
      

      {isLoading || !isDataFetched ? (
        <Flex justifyContent="center" alignItems="center" height="50vh" minWidth="50vh">
          <Spinner />
        </Flex>
      ) : (
        <CalendarTable
          startDate={startDate}
          endDate={endDate}
          selectedUnitFilter={selectedUnitFilter}
          shifts={shiftData}
          searchAllShifts={() => searchShifts()}
          units={sortedShiftsSummary}
          statusFilter={statusFilter}
        />
      )}
    </Flex>
  );
};

export default CalendarPage;
