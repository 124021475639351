import React, {useEffect} from 'react';

import {Flex, Text, useBreakpointValue} from '@chakra-ui/react';
import CreateShiftForm from './CreateShiftForm/CreateShiftForm';
// import MobileNotification from '../../../shared/components/MobileNotification/MobileNotification';
import {trackShiftPageCreate} from '../../../shared/mixpanel/page-views';

const CreateShift = () => {
  useEffect(() => {
    trackShiftPageCreate();
  }, []);

  const formWidth = useBreakpointValue({
    base: '50vh',
    md:'100%'
  });

  return (
    <Flex flexDirection="column" height="100%" margin={["1rem 0.1rem" , "1rem 1rem"]} overflow="auto" overflowX='hidden' marginRight="-16px" minWidth={formWidth} >
      {/* <MobileNotification /> */}
      {/* Heading */}
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        marginBottom="1.5rem"
        width="100%"
      >
        <Text fontWeight="700" fontSize="1.5rem">
          Create new shifts
        </Text>
        <Text>Choose from your shift templates</Text>
      </Flex>
      <CreateShiftForm />
    </Flex>
  );
};

export default CreateShift;
